import './Macro.css';
import React, { Component } from 'react';
import { extend, validateChars, expandMacros } from './utils';
import DebouncedTextarea from './DebouncedTextarea';
import DebouncedInput from './DebouncedInput';

class Macro extends Component {
  constructor (props) {
    super (props)
    this.state = {}
  }

  updateMacroState (macro) {
   this.props.owner.setMacroState (this.props.macro.name, macro);
  }

  updateMacroProp (propName, value) {
    if (propName === 'name' && value.toLowerCase() !== this.props.macro.name.toLowerCase())
      value = this.props.owner.uniqueMacroName (value, '')
    let update = extend ({}, this.props.macro);
    update[propName] = value;
    this.updateMacroState (update);
  }

  macroStateUpdater (propName) {
    return (evt) => this.updateMacroProp (propName, evt.target.value);
  }

  evalPrompt() {
    return expandMacros ({ text: this.props.macro.prompt || '', card: this.props.card, macroByName: this.props.macroByName, cardByID: this.props.cardByID });
  }

  evalMacro() {
    return expandMacros ({ text: this.props.macro.text || '', card: this.props.card, macroByName: this.props.macroByName, cardByID: this.props.cardByID });
  }

  callBot (prompt) {
    this.props.owner.callSuggestAPI ({  prompt,
                                        type: 'prompt',
                                        pendingCallback: (cb) => this.setState ({ thinking: true }, cb),
                                        timeoutCallback: () => this.setState ({ thinking: false }),
                                        doneCallback: (data) => {
                                            this.setState ({ thinking: false },
                                              () => this.updateMacroProp ('text', data.content))
                                        },
                                      })
  }

  render() {
    return (
      <div className="Macro">
        <div className="header">
        <div className="name">
          <DebouncedInput
            size="20"
            placeholder="Macro name"
            value={this.props.macro.name}
            validateChars={validateChars}
            onChange={this.macroStateUpdater ('name')} 
            debounceDelay={2000}
          />
        </div>
        <div className="prompt">
          <DebouncedInput
            value={this.props.macro.prompt || ''}
            size="60"
            placeholder="Prompt"
            onChange={this.macroStateUpdater ('prompt')}
            />
            {this.state.thinking ? (<span className="thinking">(...thinking...)</span>) : (<button
              onClick={()=>this.callBot (this.evalPrompt())}>
            Suggest
          </button>)}
        </div>
        </div>
              <div className="text">
                <DebouncedTextarea 
                    cols="90"
                    rows="4"
                    value={this.props.macro.text || ''} 
                    placeholder="Macro definition"
                    onChange={this.macroStateUpdater ('text')} 
                  />
              </div>
              <div className="expansion" dangerouslySetInnerHTML={{__html:typeof(this.state.expansion)==='undefined'?'':(this.state.expansion||'<i>Expands to empty string</i>')}}/>
        <div className="controls">
          <button
            onClick={()=>this.setState({expansion:this.evalMacro()})}
          >Expand
          </button>
          <button
            onClick={()=>this.props.owner.deleteMacro (this.props.macro)}
          >Delete
          </button>
        </div>
      </div>
    )
  }

}

export default Macro;
