import './StageMap.css';
import { extend, validateCharsToLower, makeObject } from './utils';

import 'reactflow/dist/style.css';

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  useReactFlow,
  useOnViewportChange,
  ReactFlowProvider,
  MarkerType,
  Handle, Position,
  getBezierPath, EdgeLabelRenderer, BaseEdge,
} from 'reactflow';
import DebouncedInput from './DebouncedInput';

import stringify from 'canonical-json';

const nodeColor = (node) => {
  const data = node.data
  if (data) {
    if (data.selected || data.sourceSelected || data.targetSelected)
      return 'purple'
    if (!data.cards)
      return '#fee'
    if (!data.incoming && !data.isStart)
      return '#fcc'
    if (data.isStart)
      return 'black'
  }
  return 'lightgray'
}

function cardCount (data) {
  return (<div className="cardcount">{data.cards}</div>) 
}
function StageMapNode({ data }) {
  let classNames = ""
  if (data.isStart)
    classNames += ' StartNode'
  if (!data.cards)
    classNames += ' NodeHasNoCards'
  if (!data.incoming && !data.isStart)
    classNames += ' NodeHasNoIncomingEdges'
  return (
    <>
      <Handle onClick={()=>data.owner.selectTarget(data.label)} className={(data.targetSelected?'SelectedHandle':'')+' '+(data.isStart||data.incoming?'DefaultHandle':'LooseEndHandle')} type="target" position={Position.Top} />
      {data.edit
      ? (<div className={"TextUpdaterNode "+classNames}>
          <DebouncedInput debounceDelay='1000' disabled={data.isStart} value={data.label} size={data.label.length+1} validateChars={validateCharsToLower} onChange={(evt)=>data.owner.renameNode(data.label,evt.target.value,data.nodeNames)} className="nodrag" />
          {cardCount(data)}
         </div>)
      : (<div className={"StageMapNode"+(data.selected?" SelectedStageMapNode":" UnselectedStageMapNode")+classNames}>{data.label}
      {cardCount(data)}
      </div>)}
      <Handle onClick={()=>data.owner.selectSource(data.label)} className={(data.sourceSelected?'SelectedHandle':'')+' '+(data.outgoing?'DefaultHandle':'LooseEndHandle')} type="source" position={Position.Bottom}/>
    </>
  );
}

const CardCountEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd}/>
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          className="edgecardcount nodrag nopan"
        >
          {data.label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

const minimapStyle = {
  height: 120,
};

const controlledNodesChange = (nodeChanges, nodes, updateNodeState, owner) => {
  updateNodeState (nodeChanges)
  let select, unselect
  nodeChanges.forEach ((nodeChange) => {
    if (nodeChange.type === 'position' && !nodeChange.dragging)
      owner.setNodePosition (nodeChange.id, nodes.find((node)=>node.id===nodeChange.id).position)
    if (nodeChange.type === 'select') {
      if (nodeChange.selected) {
        select = true
        owner.selectNode (nodeChange.id)
      } else
        unselect = true
    }
    if (nodeChange.type === 'remove')
      owner.deleteNode (nodeChange.id)
  })
  if (unselect && !select)
    owner.unselectNodes()
}

const controlledEdgesChange = (edgeChanges, edges, updateEdgeState, owner) => {
  updateEdgeState (edgeChanges)
  let select, unselect
  edgeChanges.forEach ((edgeChange) => {
    if (edgeChange.type === 'select') {
      if (edgeChange.selected) {
        const edge = edges.find((edge)=>edge.id===edgeChange.id)
        owner.selectEdge (edge.source, edge.target)
        select = true
      } else
        unselect = true
    }
    if (edgeChange.type === 'remove') {
      const edge = edges.find((edge)=>edge.id===edgeChange.id)
      owner.deleteEdge (edge.source, edge.target)
    }
  })
  if (unselect && !select)
    owner.unselectEdges()
}

const controlledAddEdge = (connectParams, addEdge, owner) => {
  owner.addEdge (connectParams.source, connectParams.target)
}


function StageMap(props) {
  return (
    <ReactFlowProvider>
      <StageMapInner {...props} />
    </ReactFlowProvider>
  );
}

const StageMapInner = (props) => {
  const owner = props.owner;
  const stageGraph = props.stageGraph;
  const sgNodes = stageGraph.nodes.map ((n) => extend (n, { data: { label: n.id } }))
  const sgEdges = stageGraph.edges.map ((e) => extend (e, {
    id: e.source+'>'+e.target,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    }},
    e.cards > 1 ? {data:{label:e.cards},type:'cardcount'} : {}))
  const [nodes, setNodes, updateNodeState] = useNodesState(sgNodes);
  const [edges, setEdges, updateEdgeState] = useEdgesState(sgEdges);

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const { project } = useReactFlow();

  // force re-render if props change
  const sgJSON = stringify(stageGraph);
  const [graphJSON, setGraphJSON] = useState(sgJSON);
  if (sgJSON !== graphJSON) {
    setNodes(sgNodes)
    setEdges(sgEdges)
    setGraphJSON(sgJSON)
  }

  // add node on edge drop
  // https://reactflow.dev/docs/examples/nodes/add-node-on-edge-drop/
  const onConnectStart = useCallback((_, { nodeId, handleType }) => {
    connectingNodeId.isSource = (handleType === 'source')
    connectingNodeId.current = nodeId
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const newPos = project({ x: event.clientX - left, y: event.clientY - top })

        if (connectingNodeId.isSource)
          owner.addEdgeToNewNode (connectingNodeId.current, newPos)
        else
          owner.addEdgeFromNewNode (connectingNodeId.current, newPos)
      }
    },
    [project, owner]
  );

  // custom node for renaming selected stage
  const nodeTypes = useMemo(() => ({ stage: StageMapNode }), []);
  const nodeNames = nodes.map ((node) => node.data.label)

  // custom edge label
  const edgeTypes = useMemo(() => ({ cardcount: CardCountEdge }), []);
  
  // add extra info to nodes
  let incoming = makeObject (nodes.map ((n) => [n.id,0]))
  let outgoing = makeObject (nodes.map ((n) => [n.id,0]))
  edges.forEach ((e) => { ++incoming[e.target]; ++outgoing[e.source] })
  const decoratedNodes = nodes
    .map ((n) => extend (n, {
      type: 'stage',
      data: extend (n.data,
      { isStart: n.id === props.owner.startStage,
        isAnon: n.anonymous,
        incoming: incoming[n.id],
        outgoing: outgoing[n.id],
        owner: props.owner,
        cards: n.cards,
        selected: n.selected,
        edit: n.edit,
        sourceSelected: n.sourceSelected,
        targetSelected: n.targetSelected },
        n.selected ? {nodeNames} : {})}))

  // allow caller to monitor viewport
  const myRef = useRef (null);
  useOnViewportChange ({ onChange: props.onViewportChange })
  useEffect (() => {
      if (props.onRender) {
        const autoLayout = nodes.filter ((node) => node.autoLayout)
        props.onRender ({ autoLayout, dim: { width: myRef.current.clientWidth, height: myRef.current.clientHeight } })
      }
    })

  // render
  return (
    <div className="wrapper" ref={reactFlowWrapper}>
    <ReactFlow
      ref={myRef}
      className="StageMap"
      proOptions={{hideAttribution:true}}
      nodes={decoratedNodes}
      edges={edges}
      nodeOrigin={[.5,.5]}
      deleteKeyCode={[]}
      onNodesChange={(nodeChanges)=>controlledNodesChange(nodeChanges,nodes,updateNodeState,owner)}
      onEdgesChange={(edgeChanges)=>controlledEdgesChange(edgeChanges,edges,updateEdgeState,owner)}
      onConnect={(newConnection)=>controlledAddEdge(newConnection,addEdge,owner)}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      onPaneClick={()=>{props.owner.unselectAll()}}
      onNodeDoubleClick={(_evt,node)=>owner.setEditNode(node.id)}
      fitView={props.fitView}
      defaultViewport={props.defaultViewport}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      >
      <MiniMap style={minimapStyle} zoomable pannable nodeColor={nodeColor} />
      <Controls />
      <Background color="#aaa" gap={16} />
    </ReactFlow>
    </div>
  );
};

export default StageMap;
