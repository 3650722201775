import './Swiper.css';
import React, { Component } from 'react';
import { extend, validateChars } from './utils';
import DebouncedTextarea from './DebouncedTextarea';
import DebouncedInput from './DebouncedInput';

function updateReward (oldName, newName, update, card, dir) {
  let swiper = card.props.card[dir], oldReward = (swiper && swiper.reward) || {}, newReward = {...oldReward}, newInfo = extend (oldReward[oldName] || {}, update);
  if (newName)
    newReward[newName] = newInfo;
  if (oldName && oldName !== newName)
    delete newReward[oldName];
  let cardUpdate = {};
  cardUpdate[dir] = { ...swiper, reward: newReward };
  card.updateCardState (cardUpdate);
}

function Reward (props) {
  const disabled = props.disabled, meters = props.meters, card = props.card, dir = props.dir, name = props.name, reward = props.reward, keyPrefix = props.keyPrefix
  const rewardInfo = name && reward[name]
  return (<div className="reward">
          <select value={name}
            disabled={disabled}
            onChange={(evt) => updateReward (name, evt.target.value, rewardInfo ? {} : { type: '+', value: 0, hint: false }, card, dir)}
           >
            <option value="">{name?"Remove":"Add"} reward</option>
            {meters.map ((meter,n) => (<option key={keyPrefix+n} value={meter}>{meter}</option>))}
          </select>
          {rewardInfo && (<>
            <select disabled={disabled} value={rewardInfo.type} onChange={(evt)=>updateReward(name,name,{type:evt.target.value},card,dir)}>
              <option value="=">=</option>
              <option value="+">+</option>
              <option value="%">%</option>
            </select>
            <DebouncedInput disabled={disabled} size="6" value={rewardInfo.value} placeholder="0" onChange={(evt)=>updateReward(name,name,{value:evt.target.value},card,dir)}/>
            <label>
              <input disabled={disabled} type="checkbox" checked={rewardInfo.hint||false} onChange={(evt)=>updateReward(name,name,{hint:evt.target.checked},card,dir)}/>
              Preview
            </label>
          </>)}
    </div>)
}

class Swiper extends Component {
  get stageType() {
    return typeof(this.props.swiper.stage) !== 'undefined' ? 'stage' : (typeof(this.props.swiper.push) !== 'undefined' ? 'push' : (typeof(this.props.swiper.pop) !== 'undefined' ? 'pop' : ''));
  }
  get stage() {
    const stageType = this.stageType;
    return stageType && this.props.swiper[stageType] ? this.props.swiper[stageType] : '';
  }

  setStageType (stageType) { this.setStageAndType (stageType, stageType==='pop' ? 1 : (this.stageType==='pop' ? '' : this.stage)); }
  setStage (stage) { this.setStageAndType (this.stageType, stage); }
  setStageAndType (newStageType, newStage) {
    const oldStageType = this.stageType, oldStage = this.stage, oldStagePos = this.props.app.state.stagePos;
    if (oldStageType !== newStageType || oldStage !== newStage) {
      let swiperUpdate = {}, stagePos, filter, autoLayoutCount = this.props.app.state.autoLayoutCount;
      if (oldStageType)
        swiperUpdate[oldStageType] = undefined;
      if (newStageType)
        swiperUpdate[newStageType] = newStage;
      if (newStage && !oldStagePos[newStage]) {
        stagePos = {...oldStagePos}
        filter = {...this.props.app.state.filter}
        if (oldStage && oldStage !== newStage && !this.props.stageCount[newStage] && this.props.stageCount[oldStage] === (this.props.mirrored ? 2 : 1)) {
          stagePos[newStage] = oldStagePos[oldStage]
          delete stagePos[oldStage]
          if (filter.stage === oldStage) filter.stage = newStage
          if (filter.source === oldStage) filter.source = newStage
          if (filter.target === oldStage) filter.target = newStage
        } else {
          autoLayoutCount = (autoLayoutCount || 0) + 1
          stagePos[newStage] = this.props.app.newNodePosition (autoLayoutCount)
        }
      }
      this.updateProps (swiperUpdate, stagePos && { stagePos, filter, autoLayoutCount });
    }
  }

  updateProp (propName, value) {
    let swiperUpdate = {};
    swiperUpdate[propName] = value;
    this.updateProps (swiperUpdate);
  }

  updateProps (swiperUpdate, appUpdate) {
    let swiper = extend ({}, this.props.swiper, swiperUpdate);
    let cardUpdate = {};
    cardUpdate[this.props.dir] = swiper;
    this.props.card.updateCardState (cardUpdate, appUpdate);
  }

  cardStateUpdater (propName) {
    return (evt) => this.updateProp (propName, evt.target.value);
  }

  render() {
    const swipeLabel = this.props.swipeLabel || ('Swipe '+this.props.dir)
    const reward = this.props.swiper.reward || {}
    const unusedMeters = this.props.meters.filter ((name) => !reward[name]).sort();
    return (
      <div className="Swiper">
        <div className="header">
          <div className="label">{swipeLabel}</div>
          <div className="hint">
            <DebouncedInput
              disabled={this.props.disabled}
              placeholder={swipeLabel + ' hint'}
              value={this.props.swiper.hint || ''}
              onChange={this.cardStateUpdater ('hint')} 
            />
          </div>
        {this.props.disabled || (<div className='makecard'>
          <button onClick={()=>this.props.card.props.owner.insertSwiperCard (this.props.card.props.card, this.props.dir, this.props.card.state.mirror)} >Insert {this.props.dir}</button>
        </div>)}
        </div>
        <div className="preview">
            <DebouncedTextarea 
              disabled={this.props.disabled}
              placeholder={swipeLabel+' preview'}
              cols="36"
              rows="3"
              value={this.props.swiper.preview || ''} 
              onChange={this.cardStateUpdater ('preview')} 
            />
          </div>
          <div className="stage">
          <select value={this.stageType}
            disabled={this.props.disabled}
            onChange={(evt) => this.setStageType (evt.target.value)} >
            <option value="">Continue stage</option>
            <option value="stage">Go to stage</option>
            <option value="push">Begin side stage</option>
            <option value="pop">End side stage</option>
          </select>
          {this.stageType && this.stageType !== 'pop'
          ? (<DebouncedInput
              disabled={this.props.disabled}
              size="25"
              debounceDelay='1000'
              className={this.props.card.targetSelected(this.props.swiper) ? "selected" : ""}
              value={this.stage}
              placeholder="Stage"
              validateChars={validateChars}
              onChange={(evt) => this.setStage (evt.target.value)}
              />)
          : ''}
        </div>
        <div className="rewards">
          {Object.keys(reward).sort().map ((meterName)=>(<Reward
            disabled={this.props.disabled}
            key={this.props.card.props.card.id+'-'+this.props.dir+'-reward-'+meterName}
            keyPrefix={this.props.card.props.card.id+'-'+this.props.dir+'-reward-'+meterName}
            meters={unusedMeters.concat([meterName]).sort()}
            card={this.props.card}
            dir={this.props.dir}
            name={meterName}
            reward={this.props.swiper.reward}
          />))}
          {unusedMeters.length > 0 && (<Reward
            disabled={this.props.disabled}
            keyPrefix={this.props.card.props.id+'-'+this.props.dir+'-newReward'}
            meters={unusedMeters}
            card={this.props.card}
            dir={this.props.dir}
            name=""
            reward={this.props.swiper.reward || {}}
          />)}
        </div>
      </div>
    )
  }

}

export default Swiper;
