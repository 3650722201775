import './Game.css';
import React, { Component } from 'react';
import { initGameState, sampleNextCard, computeMeterLevel, getMeterMinMax, expandText } from './Dealer';
import GameIcons from './GameIcons';
import { cloneDeep } from './utils';

const GameSwiper = (props) => {
    return (<div className={"GameSwiper "+props.className}>
              <button
                 onClick={()=>props.owner.swipe(props.swiper)}
              >{props.swiper.hint || props.defaultHint}</button>
              <div className="preview">{props.swiper.preview}</div>
            </div>);
};

const GameStatusButton = (props) => {
  return (<div className={"GameStatusButton "}>
            <button
               onClick={()=>props.owner.setState({showingStatus:!props.owner.state.showingStatus})}
            >{props.owner.state.showingStatus ? "Return to game" : "Status"}</button>
          </div>);
};

class Game extends Component {
  constructor(props) {
    super (props);
    this.state = { showingStatus: false }
  };

  reset() {
    const sort = this.props.owner.state.filter.sort
    this.props.owner.setState ({
      game: undefined,
      filter: {...this.props.owner.state.filter,
        idPlayed: false,
        playable: false, 
        sort: sort==='eval' || sort==='turn' ? undefined : sort
      }
    })
  }

  restart() {
    this.setState ({ showingStatus: false},
    () => this.props.owner.setState ({ game: undefined},
      ()=>this.startGame()))
  }

  makeIcon (meter, alt, props) {
    return (<img {...props||{}} alt={alt+' ('+meter.name+')'} src={`data:image/svg+xml;utf8,${meter.iconText || GameIcons[meter.iconName] || ''}`} />)
  }
  
  meterLevel (meter) {
    return computeMeterLevel (meter, this.gameState)
  }

  meterLevelPercentage (meter) {
    const level = this.gameState[meter.name]
    const { min, max } = getMeterMinMax (meter)
    return level < min ? '<0' : level > max ? '>100' : Math.round(100 * computeMeterLevel (meter, this.gameState))
  }

  // Accessors
  get card() { return this.props.game && this.props.game.card }
  get lastCardContext() { return this.props.game && this.props.game.lastCardContext }
  get cardContext() { return this.props.game || {} }

  get turns() { return (this.cardContext.turns && this.cardContext.turns.total) || 0 }
  get dealt() { return this.turns + (this.card ? 1 : 0) }

  get cards() { return this.props.cards }
  get meters() { return this.props.meters }
  get status() { return this.props.status }

  get next() { return this.card && this.card.next }
  get left() { return this.card && this.card.left }
  get right() { return this.card && this.card.right }

  get gameState() { return this.props.game && this.props.game.gameState }
  set gameState (gameState) { this.props.owner.setState ({ game: { ...this.props.game, gameState } }) }

  render() {
      return (<div className="Game">
                <div className="controls">
                  <button onClick={()=>this.setState({hide:!this.state.hide})}>{this.state.hide?"Show":"Hide"}</button>
                  {this.state.hide || (<>
                    <button onClick={()=>this.reset()}>Reset</button>
                    <button onClick={()=>this.restart()}>{this.dealt?"Restart":"Start"}</button>
                    <button disabled={!this.dealt || !(this.left && this.right)} onClick={()=>this.redeal()}>Redeal</button>
                    <button disabled={!this.dealt} onClick={()=>this.undo()}>Undo</button>
                  </>)}
                </div>
                <div className="GameArea">
                {this.state.hide || (<>
               <div className="meters">
                 {this.dealt > 0 ? this.meters.filter((meter) => (meter.iconName || meter.iconText) && !meter.isBadge).map ((meter, n) => {
                    return (<div className="meter" key={"game-meter-"+n}>
                             <div className="icon">{this.makeIcon(meter,"Meter icon")}</div>
                              <span>{meter.name}={this.gameState[meter.name]} ({this.meterLevelPercentage(meter)}%)</span>
                            </div>)
                 }) : (<div className="GameTitle">{this.props.owner.state.title || (<em>Untitled</em>)}</div>)}
               </div>
               {this.state.showingStatus
               ? (<div className='GameCard GameStatus'>
                  <div>
                    <div className="badges">{this.meters.filter((meter) => (meter.iconName || meter.iconText) && meter.isBadge)
                                            .map ((meter,n) => this.makeIcon(meter,"Badge icon",{key:"game-badge-"+n,className:"badge",style:{opacity:this.meterLevel(meter)}}))}
                                            </div>
                    <div className="text">{expandText (this.status, this.gameState, this.meters)}</div>
                  </div>
               </div>)
               : (<div className={'GameCard '+(this.card ? 'GameOn' : (this.dealt ? 'GameOver' : 'GameReady'))}>

                  <div className={this.card && this.card.cssClass}>
                    {this.card && (this.card.iconText || this.card.iconName)
                     && (<div className="iconContainer"><div className="icon"><img alt="Card icon" src={`data:image/svg+xml;utf8,${this.card.iconText || GameIcons[this.card.iconName] || ''}`} /></div></div>)}
                    <div className="text">{this.card && this.card.html}</div>
                    </div>
                </div>)}
                {(this.left && this.right &&
                  (<div className="GameSwipers">
                    {!this.state.showingStatus && (<GameSwiper owner={this} swiper={this.left} className="left-swiper" defaultHint="Swipe left"/>)}
                   <GameStatusButton owner={this}/>
                   {!this.state.showingStatus && (<GameSwiper owner={this} swiper={this.right} className="right-swiper" defaultHint="Swipe right"/>)}</div>))
                   || (<div className="GameSwipers">
                    {this.dealt
                    ? (<div className="GameSubtitle" onClick={()=>this.resetGame()}>No cards available</div>)
                    : (<div className="GameSubtitle" onClick={()=>this.startGame()}>Click "Start" to begin</div>)}
                    </div>)}
                  </>)}
                </div>
                <div className="debug">
                {this.gameState && !this.state.hide && (<>
                <div>Turns: {this.turns}</div>
                <div className="cardinfo">
                {this.card &&
                (<>
                 <div>Card: <button onClick={()=>{
                  let update = {filter:{...this.props.owner.state.filter,hideAll:true,bypass:{...this.props.owner.state.filter.bypass||{}}}};
                  update.filter.bypass[this.card.id] = true;
                  this.props.owner.setState(update)}}>{this.card.id}</button></div>
                 <div>Priority: {this.card.priority || 0}</div>
                 <div>Weight: {this.cardContext && this.cardContext.cardWeightByID &&
                (this.cardContext.cardWeightByID[this.card.id]
                +"/"+Object.keys(this.cardContext.cardWeightByID).reduce((sum,id)=>sum+this.cardContext.cardWeightByID[id],0))}</div>
                </>)}
                </div>
                <div className="breadcrumbs-header">Stage:</div>
                <div className="breadcrumbs">
                  {this.gameState.$.stage.map((stage,n)=>
                  (<div
                     className={"breadcrumb"+(this.props.owner.state.filter.stage===stage?" selected":"")}
                     key={"crumb-"+n}
                    ><button onClick={()=>this.props.owner.selectNode(stage)}>{stage}</button>
                    {this.cardContext && this.cardContext.turns &&
                     (<span className="turns"> ({this.cardContext.turns.byStage[n]}/{this.cardContext.turns.totalByStage[stage]||0})</span>)}
                  </div>))}
                  </div></>)}
                </div>
              </div>)
    }

    startGame() {
      const gameState = initGameState (this.meters);
      this.setState ({ showingStatus: false },
        () => this.dealCard ({ gameState }))
    }
  
    dealCard (update) {
      update = update || {}
      const nextCardContext = sampleNextCard (this.cards, this.meters, update.gameState || this.gameState);
      this.props.owner.setState ({ game: { ...this.props.game, ...nextCardContext, ...update } })
    }
  
    swipe (swiper) {
      this.dealCard ({ gameState: swiper.gameState,
                       lastCardContext: cloneDeep (this.props.game) })
    }

    undo() {
      this.setState ({ showingStatus: false }, () => {
        if (this.lastCardContext)
          this.props.owner.setState ({ game: this.lastCardContext })
        else
          this.reset()
      })
    }
    
    redeal() {
      this.setState ({ showingStatus: false }, () => {
        if (this.card)
          this.dealCard()
        else
          this.startGame()
      })
    }

};

export default Game;
