function isArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]'
}

function extend (dest) {
  dest = dest || {};
  Array.prototype.slice.call (arguments, 1).forEach (function (src) {
    if (src)
      Object.keys(src).forEach (function (key) { dest[key] = src[key]; })
  });
  return dest;
}

function cloneDeep (obj) {
  return JSON.parse(JSON.stringify(obj));
}

function fromEntries (props_values) {
  var obj = {};
  props_values.forEach (function (prop_value) {
    obj[prop_value[0]] = prop_value[1];
  });
  return obj;
}

function download(content, fileName, contentType) {
  let a = document.createElement("a");
  const file = new Blob([content], {type: contentType});
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

function readTextFile (cb) {
  let input = document.createElement('input');
  input.type = 'file';

  input.onchange = e => { 
    let file = e.target.files[0]; 
    let reader = new FileReader();
    reader.readAsText(file,'UTF-8');
    reader.onload = readerEvent => {
        const content = readerEvent.target.result;
        cb (content);
    }
  }
  input.click();
}

const invalidFirstCharRegExp = new RegExp ('^[^a-z_]', 'ig');
const invalidCharRegExp = new RegExp ('[^a-z0-9_]', 'ig');
const invalidStageListCharRegExp = new RegExp ('[^a-z0-9_,]', 'ig');
function validateChars (x) {
  return x.replaceAll(' ','_').replace(invalidFirstCharRegExp,'').replaceAll (invalidCharRegExp, '')
}
function validateCharsToLower (x) {
  return validateChars(x).toLowerCase()
}
function validateStageList (x) {
  return x.replaceAll(' ','_').replace(invalidFirstCharRegExp,'').replaceAll (invalidStageListCharRegExp, '')
}

function makeObject(array) {
  return array.reduce(function(obj, next) {
    obj[next[0]] = next[1];
    return obj;
  }, {});
}

//const atob = (window && window.atob) || ((x) => Buffer.from(x,'base64').toString());
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const uint8ToBase64 = (arr) =>
    btoa(
        Array(arr.length)
            .fill('')
            .map((_, i) => String.fromCharCode(arr[i]))
            .join('')
    );

// edges is array of two-item [source,target] arrays
function tarjan (edges) {
  let isVertex = {}, hasOutgoing = {}, hasIncoming = {};
  edges.forEach ((edge) => {
    const source = edge[0], target = edge[1];
    isVertex[source] = isVertex[target] = true;
    hasOutgoing[source] = hasOutgoing[source] || {};
    hasOutgoing[source][target] = true;
    hasIncoming[target] = hasIncoming[target] || {};
    hasIncoming[target][source] = true;
  })
  const vertices = Object.keys(isVertex).sort(),
        sources = Object.keys(hasOutgoing).sort(),
        targets = Object.keys(hasIncoming).sort(),
        targetsOf = (source) => Object.keys(hasOutgoing[source]).sort(),
        sourcesOf = (target) => Object.keys(hasIncoming[target]).sort(),
        successors = makeObject(sources.map ((source) => [source, targetsOf(source)])),
        predecessors = makeObject(targets.map ((target) => [target, sourcesOf(target)]));

  let varsByName = makeObject (vertices.map ((name) => [name, {name}]));  // holds { index, lowlink, onStack }
  let index = 0, S = [], result = [];

  function strongconnect(v) {
      // Set the depth index for v to the smallest unused index
      v.index = index;
      v.lowlink = index;
      index = index + 1;
      S.push(v);
      v.onStack = true;
    
      // Consider successors of v
      (successors[v.name] || []).forEach ((wName) => {
        let w = varsByName[wName];
        if (typeof(w.index) === 'undefined') {
          // Successor w has not yet been visited; recurse on it
          strongconnect(w)
          v.lowlink = Math.min (v.lowlink, w.lowlink);
        } else if (w.onStack) {
          // Successor w is in stack S and hence in the current SCC
          // If w is not on stack, then (v, w) is an edge pointing to an SCC already found and must be ignored
          v.lowlink = Math.min(v.lowlink, w.index);
        }
      });
    
      // If v is a root node, pop the stack and generate an SCC
      if (v.lowlink === v.index) {
          // start a new strongly connected component
          let w, scc = [];
          do {
              w = S.pop();
              w.onStack = false;
              scc.push (w.name);
          } while (w.index !== v.index);
          result.push (scc.reverse());
        }
    }

  vertices.forEach ((vName) => {
    let v = varsByName[vName];
    if (typeof(v.index) === 'undefined')
      strongconnect(v);
  });
  const stronglyConnectedComponents = result.reverse();

  const flatten = (listOfLists) => listOfLists.reduce ((flat,list)=>flat.concat(list),[]);
  const toposort = flatten (stronglyConnectedComponents);

  // effectivePredecessors[v] = (union_{u \in SCC(v)} predecessors(u)) \setminus SCC(v)
  const effectivePredecessors = stronglyConnectedComponents.reduce ((effPred, scc) => {
    let sccEffPred = {};
    scc.forEach ((u) => { (predecessors[u] || []).forEach ((t) => { sccEffPred[t] = true; }); });
    scc.forEach ((u) => { delete sccEffPred[u]; });
    const T = Object.keys(sccEffPred);
    scc.forEach ((u) => { effPred[u] = T; });
    return effPred;
  }, {});

  let parent = {};
  const mrca = (nodeList) => {
    if (nodeList.length === 0)
      return null;
    if (nodeList.length === 1)
      return nodeList[0];
    if (nodeList.length === 2) {
      let a = nodeList[0], b = nodeList[1];
      let isAncestorOfA = {};
      while (a) {
        isAncestorOfA[a] = true;
        a = parent[a];
      }
      while (b && !isAncestorOfA[b]) {
        b = parent[b];
      }
      return b || null;
    }
    // nodeList.length > 2
    return mrca ([nodeList[0], mrca (nodeList.slice(1))]);
  };
  toposort.forEach ((v) => {
    const preds = effectivePredecessors[v] || [];
    parent[v] = mrca (preds);
  });

  return { parent, toposort, stronglyConnectedComponents, successors, predecessors, effectivePredecessors };
}

const macroRegex = new RegExp ("(\\\\[\\\\\\$@<>\\?]|\\$([a-z_][a-z0-9_]*|[<>\\?])|@[a-z_][a-z0-9_]*\\$([a-z_][a-z0-9_]*|[<>\\?])|@\\$[a-z_][a-z0-9_]*)",'ig');
const macroEscapeRegex = new RegExp ("\\\\([\\\\\\$\\[\\]\\|@<>\\?])", 'g');
const altRegex = new RegExp ("(\\\\\\[|\\[[^\\[\\]\\|]*(\\|[^\\[\\]\\|]*)+\\])", 'g');
const expandMacros = (args) => {
  let { text, card, macroByName, cardByID, getAllDependentFields, nested, nestedCard, isDependentField, ignoreMacro } = args;
  const cardMacro = card && card.macro ? {...macroByName, ...card.macro} : macroByName;
  isDependentField = isDependentField || {}
  ignoreMacro = ignoreMacro || {}
  text = text.replaceAll (macroRegex, (match) => {
    const macro = match.toLowerCase();
    if (ignoreMacro[macro])  // prevent recursive cycles
      return '';
    let innerIgnoreMacro = { ...ignoreMacro };
    innerIgnoreMacro[macro] = true;
    let innerArgs = { ...args, isDependentField, nested: true, ignoreMacro: innerIgnoreMacro };
    const macroChar = match[0], macroName = match.substr(1).toLowerCase();
    switch (macroChar) {
      case '\\':
        return match;
      case '$': {
        switch (macroName) {
          case '?':
            return (card && card.html) || '';
          case '<':
            return (card && card.left && card.left.preview) || '';
          case '>':
            return (card && card.right && card.right.preview) || '';
          default: {
            if (!macroByName[macroName] && !nestedCard)
              isDependentField[macroName] = true;
            return cardMacro[macroName] ? expandMacros ({ ...innerArgs, text: cardMacro[macroName] }) : '';
          }
        }
      }
      case '@': {
        const card_field = macroName.split('$'), macroCardID = card_field[0], macroField = card_field[1];
        let macroDef;
        if (!macroCardID && !nestedCard) {
          isDependentField[macroField] = true;
          if (card)
            macroDef = (card.macro && card.macro[macroField]) || '';
          else
            return '';
        } else
          macroDef = '$' + macroField;
        return expandMacros ({ ...innerArgs, text: macroDef, card: macroCardID ? cardByID[macroCardID] : card, nestedCard: nestedCard || !!macroCardID });
      }
      default:
        throw new Error("unknown macro " + match);
    }
  })
  if (!nested) {
    // expand alternations
    if (!getAllDependentFields) {
      let foundAlt;
      do {
        foundAlt = false;
// eslint-disable-next-line 
        text = text.replaceAll (altRegex, (match) => {
          if (match[0] === '[') {
            foundAlt = true;
            const alt = match.substr(1,match.length-2).split('|');
            const idx = Math.floor (Math.random() * alt.length);
            return alt[idx];
          }
          return match;
        });
      } while (foundAlt);
    }
    // do final markdown-ish substitutions
    text = text.replaceAll (macroEscapeRegex, (_m,g)=>g).replaceAll('\n','<p>');
  }
  return text;
}

function getDependentFields (args) {
  let isDependentField = {};
  expandMacros ({ ...args, isDependentField });
  return Object.keys(isDependentField).sort();
}


export { isArray, extend, cloneDeep, fromEntries, makeObject,
          download, readTextFile, parseJwt, uint8ToBase64, 
          validateChars, validateCharsToLower, validateStageList,
          tarjan,
          expandMacros, getDependentFields,
        };

