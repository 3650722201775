import { debounce } from 'lodash'
import React, { Component } from 'react';
import { cloneDeep } from './utils';

class DebouncedTextarea extends Component {
  constructor(props){
    super(props)
    this.prevProps = cloneDeep (props)
    this.state = {
      unedited: this.props.unedited,
      value: props.value
    }
    this.textareaRef = (this.props.autoFocus && !this.props.disabled && React.createRef()) || undefined
    // debounce the passed in dispatch method
    this.changed = debounce(this.props.onChange, 250)
  }
  componentDidUpdate() {
    if (this.props.value !== this.prevProps.value)
        this.setState ({ value: this.props.value },
            () => this.prevProps.value = this.props.value)
  }
  handleChange = e => {
    // React event weirdness requires storing
    // the synthetic event
    const val = e.target.value, oldVal = this.state.value
    this.setState({ value: val, unedited: this.state.unedited && val === oldVal }, () => {
      this.changed({target:{value:val}})
    })
  }
  render() {
    return (
      <textarea
        className={this.state.unedited ? this.props.uneditedClassName : this.state.value ? this.props.className : this.props.emptyClassName}
        disabled={this.props.disabled}
        autoFocus={this.props.autoFocus && !this.props.disabled}
        ref={this.textareaRef}
        rows={this.props.rows}
        cols={this.props.cols}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        value={this.state.value}
        />
    )
  }
  componentDidMount() {
    if (this.textareaRef && this.textareaRef.current) {
      this.textareaRef.current.selectionStart = 0
      this.textareaRef.current.selectionEnd = this.state.value.length
      this.textareaRef = null
    }
  }
}

export default DebouncedTextarea