// Card properties include
//      weight: string which evaluates to a number (can be a dynamically evaluated expression involving named meters)
//    priority: zero by default. Only cards with the highest priority and nonzero weight are eligible to be dealt
//        when: if present, must be a string (split by commas into array of strings), one of which must match the last element of the gameState.stage array
//        html: string; may include curly brace-delimited expressions that are evaluated like "weight"
//    cssClass: string
// left, right: optional swiper objects that can contain { hint, preview, meters, reward, stage, push, pop }
// limit, minTurnsAtStage, maxTurnsAtStage, minTotalTurnsAtStage, maxTotalTurnsAtStage, minTurns, maxTurns: limit when/how many times a particular card can be dealt.
//        cool: cooling-off period i.e. number of cards dealt *from the same stage* before the card can be dealt again
//        type: string that identifies the type of this card. Used by typeLimit and typeCool
//   typeLimit: like 'limit', but specifies an upper bound on the number of times a card of this *type* can be dealt (not just this card)
//    typeCool: like 'cool', but specifies a lower bound on the number of turns that must have passed at this stage before a card of this type was last dealt

// Meter properties are { min, max, init, iconName, iconText, isBadge }

// If a swiper has a 'reward' property, then this is used as a name=>{type,hint,value} map for the meter.
// Type can be '=' (value is new value for meter), '+' (value is an increment for meter), or '%' (value is a percentage of the distance to corresponding max/min)
// so e.g. if type==='%' and value===-50, then newMeterLevel = oldMeterLevel - 50% * (oldMeterLevel - minimumMeterLevel)
// If 'hint' is true, then half-swiping will give a hint that the meter level is changing.

const startStage = 'start';

function initGameState (meters) {
  let gameState = {
    $: {
      stage: [startStage],
      turns: {
        byCard: {},
        byStage: [0],
        totalByStage: {},
        total: 0
      },
      history: {
        byStage: [[]],
        complete: [],
      },
    }
  };
  meters.forEach ((meter) => {
    const min = typeof(meter.min) === 'undefined' ? 0 : meter.min;
    const max = typeof(meter.max) === 'undefined' ? 1 : meter.max;
    const init = typeof(meter.init) === 'undefined' ? (min + max)/2 : meter.init;
    gameState[meter.name] = init;
  });
  return gameState;
}

function currentStage (gameState) {
  const stage = gameState.$.stage;
  return stage.length ? stage[stage.length - 1] : undefined;
}

function makeFunction (str, meters) {
  let f = () => str;
  const expr = '($'+meters.map((meter)=>','+meter.name).join('')+')=>'+(typeof(str)==='string'?str.replaceAll(/\b=\b/g,'==='):str);
// eslint-disable-next-line
  try { f = eval(expr); } catch (e) { }
  return f;
}

function makeWeightArgs (gameState, meters) {
  return [gameState].concat (meters.map ((meter) => gameState[meter.name]));
}

function applyOrNull (f, args) {
  let result = null;
  try {
    result = f.apply (null, args);
  } catch (e) {
    // do nothing
  }
  return result;
}

function sampleByWeight (weights) {
  let totalWeight = weights.reduce (function (total, w) { return total + w }, 0)
  if (totalWeight) {
    let w = totalWeight * Math.random()
    for (let i = 0; i < weights.length; ++i)
      if ((w -= weights[i]) <= 0)
        return i
  }
  return undefined
}

function evalSwiper (swiper, card, meters, oldGameState) {
  const stage = currentStage (oldGameState);
  const weightArgs = makeWeightArgs (oldGameState, meters);
  let meterHint = {};
  let gameState = cloneDeep(oldGameState);
  let gs = gameState.$;
  if (card) {
    if (card.limit)
      gs.turns.byCard[card.id] = (gs.turns.byCard[card.id] || 0) + 1
    gs.turns.byStage[gs.turns.byStage.length - 1] = (gs.turns.byStage[gs.turns.byStage.length - 1] || 0) + 1
    gs.turns.totalByStage[stage] = (gs.turns.totalByStage[stage] || 0) + 1
    gs.turns.total++
    gs.history.byStage[gs.history.byStage.length - 1].push (card.id)
    gs.history.complete.push (card.id)
  }
  if (swiper) {
    if (swiper.reward)
        Object.keys(swiper.reward).forEach ((name) => {
          const rewardInfo = swiper.reward[name];
          let evalReward = applyOrNull (makeFunction (rewardInfo.value, meters), weightArgs);
          let oldLevel = oldGameState[name];
          const meter = meters.find ((m) => m.name === name);
          if (meter) {
            const { min, max } = getMeterMinMax (meter);
            switch (rewardInfo.type) {
              case '=':
                gameState[name] = evalReward;
                break;
              case '%':
                evalReward = parseFloat (evalReward) || 0;
                oldLevel = parseFloat (oldLevel) || 0;
                gameState[name] = oldLevel + (evalReward > 0
                                    ? (max - oldLevel)
                                    : (oldLevel - min))
                                    * Math.abs(evalReward) / 100;
                break;
              case '+':
              default:
                evalReward = parseFloat (evalReward) || 0;
                oldLevel = parseFloat (oldLevel) || 0;
                gameState[name] = oldLevel + evalReward;
                break;
            }
            if (rewardInfo.hint && gameState[name] !== oldLevel)
              meterHint[name] = Math.sign(gameState[name] - oldLevel);
          }
        })
    if (swiper.pop) {
      let pops = Math.min (gs.stage.length, typeof(swiper.pop) === 'number' ? swiper.pop : 1)
      for (let n = 0; n < pops; ++n) {
        gs.stage.pop()
        gs.turns.byStage.pop()
        gs.history.byStage.pop()
      }
    }
    if (swiper.push) {
      const toPush = [swiper.push.toLowerCase()];
      gs.stage = gs.stage.concat (toPush);
      toPush.forEach ((stage) => {
        gs.turns.byStage.push (0)
        gs.history.byStage.push ([])
      });
    }
    if (swiper.stage) {
      gs.stage.pop()
      gs.turns.byStage.pop()
      gs.history.byStage.pop()
      gs.stage.push (swiper.stage.toLowerCase())
      gs.turns.byStage.push (0)
      gs.history.byStage.push ([])
    }
  }
  return { hint: swiper && swiper.hint,
           preview: swiper && swiper.preview,
           meterHint,
           gameState };
}

function sampleNextCard (cards, meters, gameState) {
  const gs = gameState.$;

  let cardByID = {};
  cards.forEach ((card) => cardByID[card.id] = card);

  const typeTurns = {}
  Object.keys(gs.turns.byCard).forEach ((cardID) => {
    const type = cardByID[cardID].type
    if (type)
      typeTurns[type] = (typeTurns[type] || 0) + 1
  })

  // Use the weight, turn limit, cool-off, and/or priority rules to arrive at a weight distribution over cards
  // Weights & turn limits
  const weightArgs = makeWeightArgs (gameState, meters);
  const stage = currentStage (gameState);
  let rawCardWeight = cards.map ((card) => {
    let w = card.weight
    if (typeof(w) === 'undefined' || w === '')
      w = 1
    else {
      if (typeof(w) === 'string')
        w = applyOrNull (makeFunction (w, meters), weightArgs);
      w = typeof(w) === 'number' || typeof(w) === 'boolean' ? Math.max(w,0) : 0;
    }
    return w;
  })
// eslint-disable-next-line
  let cardWeight = cards.map ((card,n) => {
    const when = card.when.toLowerCase().split(',');
    if (card.when && when.indexOf (stage) < 0)
      return 0
    let turnsByCard = gs.turns.byCard[card.id] || 0
    let turnsByType = card.type ? typeTurns[card.type] : turnsByCard
    let turnsByStage = gs.turns.byStage[gs.turns.byStage.length-1] || 0
    let turnsTotalByStage = gs.turns.totalByStage[stage]
    if ((card.limit && turnsByCard >= card.limit)
        || (card.type && card.typeLimit && turnsByType >= card.typeLimit)
        || (card.minTurnsAtStage && turnsByStage < card.minTurnsAtStage)
        || (card.maxTurnsAtStage && turnsByStage > card.maxTurnsAtStage)
        || (card.minTotalTurnsAtStage && turnsTotalByStage < card.minTotalTurnsAtStage)
        || (card.maxTotalTurnsAtStage && turnsTotalByStage > card.maxTotalTurnsAtStage))
      return 0
    return rawCardWeight[n] * (card.weightMultiplier ? parseFloat(card.weightMultiplier) : 1);
    })
  // Cool-off
  const history = gs.history.complete || []
  const turnsSincePlayed = cards.map ((card) => {
    const last = history.lastIndexOf(card.id)
    return last >= 0 ? history.length - 1 - last : undefined
  })
  cardWeight = cardWeight.map ((w, n) => typeof(turnsSincePlayed[n])==='undefined' || turnsSincePlayed[n] >= (cards[n].cool || 0) ? w : 0)

  const cardHistory = history.map ((cardID) => cardByID[cardID])
  let turnsSinceCardPlayed = {}, turnsSinceTypePlayed = {}, turnsByCard = {}, turnsByType = {}
  cardHistory.slice(0).reverse().forEach ((card,n) => {
    turnsByCard[card.id] = (turnsByCard[card.id] || 0) + 1
    turnsByType[card.type || ''] = (turnsByType[card.type || ''] || 0) + 1
    if (typeof(turnsSinceCardPlayed[card.id]) === 'undefined')
      turnsSinceCardPlayed[card.id] = n;
    if (typeof(turnsSinceTypePlayed[card.type]) === 'undefined')
      turnsSinceTypePlayed[card.type || ''] = n;
  })

  const turnsSinceCardTypePlayed = cards.map ((card) => turnsSinceTypePlayed[card.type || ''])
  cardWeight = cardWeight.map ((w, n) => !cards[n].type || typeof(turnsSinceCardTypePlayed[n])==='undefined' || turnsSinceCardTypePlayed[n] >= (cards[n].typeCool || 0) ? w : 0)
  // Priority
// eslint-disable-next-line
  let maxPriority = cards.reduce ((mp, card, n) => {
    let priority = card.priority || 0
    if (cardWeight[n] > 0 && (typeof(mp) === 'undefined' || priority > mp))
      mp = priority
    return mp
  }, undefined)
  cardWeight = cardWeight.map ((w, n) => (cards[n].priority || 0) === maxPriority ? w : 0)

  let cardWeightByID = {}, rawCardWeightByID = {}
  rawCardWeight.forEach ((w,n) => rawCardWeightByID[cards[n].id] = w)
  cardWeight.forEach ((w,n) => cardWeightByID[cards[n].id] = w)

  const turns = cloneDeep(gs.turns);
  const template = cards[sampleByWeight (cardWeight)];
  const card = template &&
               { ...template,
                 html: expandText (template.html || '', gameState, meters),
                 left: evalSwiper (template.left || {}, template, meters, gameState),
                 right: evalSwiper (template.right || {}, template, meters, gameState) }

  return { cardWeight, cardWeightByID,
           rawCardWeight, rawCardWeightByID,
           turnsByCard, turnsByType, turnsSinceCardPlayed, turnsSinceTypePlayed,
           stage, turns, template, card }
}

function getMeterMinMax (meter) {
  const min = typeof(meter.min) === 'undefined' ? 0 : meter.min;
  const max = typeof(meter.max) === 'undefined' ? 1 : meter.max;
  return { min, max }
}

function computeMeterLevel (meter, gameState) {
  const { min, max } = getMeterMinMax (meter);
  const val = typeof(gameState[meter.name]) === 'undefined' ? 0 : (parseFloat(gameState[meter.name]) || 0);
  return Math.min (1, Math.max (0, (val - min) / (max - min)));
}

function expandText (text, gameState, meters) {
  const weightArgs = makeWeightArgs (gameState, meters);
  return text.replaceAll (/{([^}]*?)}/g, (_m,g)=>applyOrNull(makeFunction(g,meters),weightArgs) || '');
}

// utility functions (redundantly defined here to minimize imports)
function cloneDeep (obj) {
  return JSON.parse(JSON.stringify(obj));
}

export { startStage, initGameState, currentStage, sampleNextCard, computeMeterLevel, getMeterMinMax, expandText, cloneDeep };
