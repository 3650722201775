import './Meter.css';
import React, { Component } from 'react';
import IconSelector from './IconSelector';
import { extend, validateCharsToLower } from './utils';
import DebouncedInput from './DebouncedInput';

class Meter extends Component {
  updateMeterState (meter) {
   this.props.owner.setMeterState (this.props.meter.name, meter);
  }

  updateMeterProp (propName, value) {
    if (propName === 'name' && value !== this.props.meter.name)
      value = this.props.owner.uniqueMeterName (value, '')
    let update = extend ({}, this.props.meter);
    update[propName] = value;
    this.updateMeterState (update);
  }

  meterStateUpdater (propName, targetProp) {
    return (evt) => this.updateMeterProp (propName, evt.target[targetProp || 'value']);
  }

  render() {
    return (
      <div className="Meter">
        <div className="name">
          <DebouncedInput
            size="20"
            placeholder="Meter name"
            value={this.props.meter.name}
            onChange={this.meterStateUpdater ('name')} 
            validateChars={validateCharsToLower}
          />
        </div>
        <div className="init">
          <DebouncedInput
            size="20"
            placeholder="Initial value"
            value={this.props.meter.init || ''}
            onChange={this.meterStateUpdater ('init')} 
          />
        </div>
        <div className="range">
          <DebouncedInput
            size="9"
            placeholder="Min"
            value={this.props.meter.min || ''}
            onChange={this.meterStateUpdater ('min')} 
          />
          <DebouncedInput
            size="9"
            placeholder="Max"
            value={this.props.meter.max || ''}
            onChange={this.meterStateUpdater ('max')} 
          />
        </div>
        <IconSelector iconName={this.props.meter.iconName} iconText={this.props.meter.iconText} onChange={({iconName,iconText})=>this.updateMeterState (extend({},this.props.meter,{iconName,iconText}))}/>
        {(this.props.meter.iconName || this.props.meter.iconText) &&
         (<label className="isbadge"><input type="checkbox" checked={this.props.meter.isBadge||false} onChange={this.meterStateUpdater('isBadge','checked')}/>Display as badge</label>)}
        <div className="metercontrols">
          <button
          onClick={()=>this.props.owner.deleteMeter (this.props.meter)}
          >Delete
          </button>
        </div>
      </div>
    )
  }

}

export default Meter;
