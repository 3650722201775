import './Card.css';
import React, { Component } from 'react';
import Swiper from './Swiper';
import IconSelector from './IconSelector';
import { extend, cloneDeep, validateStageList, expandMacros, getDependentFields } from './utils';
import stringify from 'canonical-json';
import DebouncedTextarea from './DebouncedTextarea';
import DebouncedInput from './DebouncedInput';

function LimitingInput (props) {
  const playValue = props.hasPlayValue && props.playValue()
  return (!props.hideUndefined || typeof(props.value) !== 'undefined') &&
          (<div className='LimitingInput'>
           {props.hasPlayValue && props.playValueShownToLeft &&
             (<span className={'eval '+(props.isLimited?'ineligible':'eligible')}>({playValue})</span>)}
            <div>
           <DebouncedInput
            {...props}
            className={(props.className||'')+' '+(props.isLimited?'ineligible':'eligible')}
           />
           {props.hasPlayValue && !props.playValueShownToLeft &&
             (<span className={'eval '+(props.isLimited?'ineligible':'eligible')}>({playValue})</span>)}
           <span className={"label"+(props.disabled?' disabled':'')}>{props.label}</span>
           </div>
           {props.hideUndefined && !props.value && (<button onClick={()=>props.onChange({target:{value:undefined}})}>(remove)</button>)}
           </div>)
}

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = { mirror: stringify(props.card.left)===stringify(props.card.right) }
  }

  setStateAndScroll (update, cb) {
    this.setState (extend (update, { scrollPosition: window.pageYOffset }), cb)
  }

  updateCardState (update, appUpdate, cb) {
    if (update.left && this.state.mirror)
      update.right = cloneDeep (update.left);
    const card = extend ({}, this.props.card, update);
    this.props.owner.setCardState (this.props.card.id, card, appUpdate, cb);
  }

  updateCardProps (propValueList, cb) {
    let update = { edited: Date.now() };
    propValueList.forEach ((propValue) => {
      update[propValue[0]] = propValue[1];
    })
    return this.updateCardState (update, undefined, cb);
  }

  updateCardProp (propName, value, cb) {
    return this.updateCardProps ([[propName, value]], cb)
  }

  cardStateUpdater (propName, cb) {
    return (evt) => this.updateCardProp (propName, evt.target.value, cb);
  }

  setFilterBypass (value, cb) {
    this.props.owner.setFilterBypass (this.props.card.id, value, cb)
  }

  callBot (prompt) {
    this.props.owner.callSuggestAPI ({  prompt,
                                        type: 'question',
                                        pendingCallback: (cb) => this.setState ({ thinking: true }, cb),
                                        timeoutCallback: () => this.setStateAndScroll ({ thinking: false }),
                                        doneCallback: (data) => {
                                          if (data.card)
                                            this.setStateAndScroll ({ mirror: false, thinking: false },
                                              () => this.updateCardState (data.card))
                                        },
                                      })
  }

  swapSwipers() {
    this.updateCardState ({ left: this.props.card.right,
                            right: this.props.card.left })
  }

  swiperStageType (swiper) {
    return typeof(swiper.stage) !== 'undefined' ? 'stage' : (typeof(swiper.push) !== 'undefined' ? 'push' : (typeof(swiper.pop) !== 'undefined' ? 'pop' : ''));
  }

  swiperStage (swiper) {
    const stageType = this.swiperStageType (swiper);
    return (stageType && typeof(swiper[stageType]) === 'string' ? swiper[stageType] : '').toLowerCase();
  }

  targetSelected (swiper) {
    return swiper && this.props.owner.state.filter && this.swiperStage(swiper) && (this.swiperStage(swiper) === this.props.owner.state.filter.stage || this.swiperStage(swiper) === this.props.owner.state.filter.target)
  }

  sourceMatches (stage) {
    const when = this.props.card.when
    return !when || (stage && when.toLowerCase().split(',').indexOf(stage.toLowerCase()) >= 0)
  }

  
  render() {
    if (this.state.mirror && stringify(this.props.card.left)!==stringify(this.props.card.right))
      setTimeout (() => this.setState ({ mirror: false }), 0)
    const id = this.props.card.id
    const sourceSelected = this.sourceMatches(this.props.owner.state.filter.stage) || this.sourceMatches(this.props.owner.state.filter.source)
    const targetSelected = this.targetSelected(this.props.card.left) || this.targetSelected(this.props.card.right)
    const limitProps = [{name:'limit',               label:'Limit times card can appear'},
                        {name:'typeLimit',           label:'Limit cards of this type', disabled: !this.props.card.type},
                        {name:'cool',                label:'Turns before this card can reappear'},
                        {name:'typeCool',            label:'Turns before this type can reappear', disabled: !this.props.card.type},
                        {name:'minTurns',            label:'Min turns before card can appear'},
                        {name:'minTurnsAtStage',     label:'Min turns at current stage'},
                        {name:'minTotalTurnsAtStage',label:'Min total turns at current stage'},
                        {name:'maxTurns',            label:"Max turns before card can't appear"},
                        {name:'maxTurnsAtStage',     label:'Max turns at current stage'},
                        {name:'maxTotalTurnsAtStage',label:'Max total turns at current stage'},
                        {name:'weight',              label:'Custom constraint'}];
    let propLabel = {};
    limitProps.forEach ((lp) => propLabel[lp.name] = lp.label);
    const undefinedLimitProps = limitProps.filter((lp)=>typeof(this.props.card[lp.name]) === 'undefined');
    const fields = getDependentFields (this.expandMacroArgs());
    let isDependentField = {}, macro = {...this.props.card.macro || {}};
    fields.forEach ((field) => { isDependentField[field] = true; macro[field] = macro[field] || '' });
    return (
      <div className={"Card"
                        +(sourceSelected||targetSelected?" selected":"")
                        +(this.props.cardWeight?(this.props.played?" played":this.props.cardWeight[id]?" eligible":" ineligible"):"")}>
        <div className="dealer">
          <div className="html">
          <div className="iconcontrols">
            <IconSelector iconName={this.props.card.iconName} iconText={this.props.card.iconText} onChange={({iconName,iconText})=>this.updateCardState({iconName,iconText})} />
          </div>
            <DebouncedTextarea
              uneditedClassName="unedited"
              emptyClassName="unedited"
              unedited={this.props.autoFocus}
              autoFocus={this.props.autoFocus}
              rows={(this.props.card.iconName || this.props.card.iconText) ? 4 : 8}
              cols="36"
              value={this.props.card.html || ''} 
              placeholder="Main content of this card"
              onChange={this.cardStateUpdater ('html', ()=>this.setState({htmlEdited:true}))} 
            />
          </div>
          <div className="meta">
            <div className="id">{this.props.card.id}</div>
          <div className="rank-and-type-conditions">
          <div className="type-conditions">
            <div className="when"> 
              <LimitingInput size="26"
                debounceDelay='1000'
                placeholder='Stage'
                className={this.props.card.when && sourceSelected ? 'selected' : ''}
                isLimited={this.props.card.when && this.props.currentStage && !this.sourceMatches(this.props.currentStage)}
                value={this.props.card.when || ''}
                onChange={this.cardStateUpdater ('when')}
                validateChars={validateStageList}
              />
            </div>
            <div className="type">
              <DebouncedInput size="26"
                placeholder='Type'
                value={this.props.card.type || ''}
                onChange={this.cardStateUpdater ('type')} 
              />
            </div>
            </div>
          <div className="rank-conditions">
            <div>
              <LimitingInput size="8"
                placeholder='Weight'
                value={this.props.card.weightMultiplier || ''}
                onChange={this.cardStateUpdater ('weightMultiplier')} 
                isLimited={this.props.card.weightMultiplier && !parseFloat(this.props.card.weightMultiplier)}
                hasPlayValue={this.props.cardWeight && typeof(this.props.cardWeight[id]) !== 'undefined'}
                playValue={()=>(this.props.cardWeight[id]||0)}
                playValueShownToLeft='1'
              />
            </div>
            <div>
              <LimitingInput size="8"
                placeholder='Priority'
                value={this.props.card.priority || ''}
                onChange={this.cardStateUpdater ('priority')}
                isLimited={typeof(this.props.dealtPriority) !== 'undefined' && (this.props.card.priority||0) < this.props.dealtPriority}
              />
            </div>
            </div>
            </div>
            <div className="repeat-conditions">
              <LimitingInput size="4"
                label="Limit times card can appear"
                hideUndefined="1"
                value={this.props.card.limit}
                onChange={this.cardStateUpdater ('limit')} 
                isLimited={this.props.card.limit && this.props.turnsByCard && (this.props.turnsByCard[id]||0) >= this.props.card.limit}
                hasPlayValue={this.props.turnsByCard}
                playValue={()=>this.props.turnsByCard[id] || 0}
              />
                <LimitingInput size="4"
                  label={propLabel.typeLimit}
                  disabled={!this.props.card.type}
                  hideUndefined="1"
                  value={this.props.card.typeLimit}
                  onChange={this.cardStateUpdater ('typeLimit')} 
                  isLimited={this.props.card.typeLimit && this.props.turnsByType && (this.props.turnsByType[this.props.card.type||'']||0) >= this.props.card.typeLimit}
                  hasPlayValue={this.props.card.type && this.props.turnsByType}
                  playValue={()=>this.props.turnsByType[this.props.card.type || ''] || 0}
                />
              <LimitingInput size="4"
                label={propLabel.cool}
                hideUndefined="1"
                value={this.props.card.cool}
                onChange={this.cardStateUpdater ('cool')}
                isLimited={this.props.card.cool && this.props.turnsSinceCardPlayed && typeof(this.props.turnsSinceCardPlayed[id])!=='undefined' && this.props.turnsSinceCardPlayed[id] < this.props.card.cool}
                hasPlayValue={this.props.turnsSinceCardPlayed && typeof(this.props.turnsSinceCardPlayed[id])!=='undefined'}
                playValue={()=>this.props.turnsSinceCardPlayed[id]}
              />
                <LimitingInput size="4"
                  label={propLabel.typeCool}
                  disabled={!this.props.card.type}
                  hideUndefined="1"
                  value={this.props.card.typeCool}
                  onChange={this.cardStateUpdater ('typeCool')} 
                  isLimited={this.props.card.typeCool && this.props.turnsSinceTypePlayed && typeof(this.props.turnsSinceTypePlayed[id])!=='undefined' && this.props.turnsSinceTypePlayed[id] < this.props.card.typeCool}
                  hasPlayValue={this.props.card.type && this.props.turnsSinceTypePlayed && typeof(this.props.turnsSinceTypePlayed[id])!=='undefined'}
                  playValue={()=>this.props.turnsSinceTypePlayed[id]}
                />
              <LimitingInput size="4"
                label={propLabel.minTurns}
                hideUndefined="1"
                value={this.props.card.minTurns}
                onChange={this.cardStateUpdater ('minTurns')} 
                isLimited={this.props.card.minTurns && (this.props.turnsTotal || 0) < this.props.card.minTurns}
              />
              <LimitingInput size="4"
                label={propLabel.minTurnsAtStage}
                hideUndefined="1"
                value={this.props.card.minTurnsAtStage}
                onChange={this.cardStateUpdater ('minTurnsAtStage')} 
                isLimited={this.props.card.when && this.props.currentStage && this.props.card.when===this.props.currentStage && this.props.card.minTurnsAtStage && this.props.turnsByStageCurrent && (this.props.turnsByStageCurrent[this.props.turnsByStageCurrent.length-1]||0) < this.props.card.minTurnsAtStage}
              />
              <LimitingInput size="4"
                label={propLabel.minTotalTurnsAtStage}
                hideUndefined="1"
                value={this.props.card.minTotalTurnsAtStage}
                onChange={this.cardStateUpdater ('minTotalTurnsAtStage')} 
                isLimited={this.props.card.minTotalTurnsAtStage && this.props.turnsByStageTotal && this.props.currentStage && (this.props.turnsByStageTotal[this.props.currentStage]||0) < this.props.card.minTotalTurnsAtStage}
              />
              <LimitingInput size="4"
                label={propLabel.maxTurns}
                hideUndefined="1"
                value={this.props.card.maxTurns}
                onChange={this.cardStateUpdater ('maxTurns')} 
                isLimited={this.props.card.maxTurns && (this.props.turnsTotal || 0) > this.props.card.maxTurns}
              />
              <LimitingInput size="4"
                label={propLabel.maxTurnsAtStage}
                hideUndefined="1"
                value={this.props.card.maxTurnsAtStage}
                onChange={this.cardStateUpdater ('maxTurnsAtStage')} 
                isLimited={this.props.card.when && this.props.currentStage && this.props.card.when===this.props.currentStage && this.props.card.maxTurnsAtStage && this.props.turnsByStageCurrent && (this.props.turnsByStageCurrent[this.props.turnsByStageCurrent.length-1]||0) > this.props.card.maxTurnsAtStage}
              />
              <LimitingInput size="4"
                label={propLabel.maxTotalTurnsAtStage}
                hideUndefined="1"
                value={this.props.card.maxTotalTurnsAtStage}
                onChange={this.cardStateUpdater ('maxTotalTurnsAtStage')} 
                isLimited={typeof(this.props.card.maxTotalTurnsAtStage)!=='undefined' && this.props.turnsByStageTotal && this.props.currentStage && (this.props.turnsByStageTotal[this.props.currentStage]||0) >= this.props.card.maxTotalTurnsAtStage}
              />
              <LimitingInput size="33"
                placeholder={propLabel.weight}
                hideUndefined="1"
                value={this.props.card.weight}
                onChange={this.cardStateUpdater ('weight')} 
                isLimited={this.props.rawCardWeight && typeof(this.props.rawCardWeight[id]) !== 'undefined' && !this.props.rawCardWeight[id]}
                hasPlayValue={this.props.card.weight && this.props.rawCardWeight && typeof(this.props.rawCardWeight[id]) !== 'undefined'}
                playValue={()=>(this.props.rawCardWeight[id]||0)}
              />
            {undefinedLimitProps.length > 0 &&
             (<div><select className="addConstraint" value="" onChange={(evt)=>this.updateCardProp(evt.target.value,'')}>
              <option value="">Add constraint</option>
              {undefinedLimitProps.map ((lp) => (<option disabled={lp.disabled} key={this.props.card.id+'-limit-'+lp.name} value={lp.name}>{lp.label}</option>))}
             </select></div>)}
          </div>
          </div>
        </div>
        <div className="player">
          <Swiper card={this} swiper={this.props.card.left || {}} dir="left" meters={this.props.meters} app={this.props.owner} stageCount={this.props.stageCount} mirrored={this.state.mirror}/>
          <div className="swipecontrols">
          <div className="mirror">
            <label>
             &larr;Mirror
             <input type="checkbox" checked={this.state.mirror||false} onChange={(evt)=>{
              const mirror = evt.target.checked;
              this.setState({mirror});
              if (mirror) {
                const right = this.props.card.right || {};
                this.updateCardState ({ left: cloneDeep(right), right });
              }
            }}/>
             </label>
          </div>
          <div className="mirror">
            <label>
             <input type="checkbox" checked={this.state.mirror||false} onChange={(evt)=>{
              const mirror = evt.target.checked;
              this.setState({mirror});
              if (mirror) {
                const left = this.props.card.left || {};
                this.updateCardState ({ right: cloneDeep(left), left });
              }
            }}/>
            Mirror&rarr;
             </label>
          </div>
          <div className="swap">
            {!this.state.mirror && (<button onClick={(evt)=>this.swapSwipers()}>&larr;swap&rarr;</button>)}
          </div>
          </div>
          <Swiper card={this} swiper={this.props.card.right || {}} dir="right" meters={this.props.meters} app={this.props.owner} stageCount={this.props.stageCount} mirrored={this.state.mirror} disabled={this.state.mirror}/>
        </div>
        {this.props.showMacros && Object.keys(macro).filter((field)=>this.props.showAuto||!(this.props.card.auto&&this.props.card.auto[field])).sort().map ((field) => (<div className="cardmacro" key={this.props.card.id+'-macro-'+field}>
          <div className="header"><div className="field">{field}</div>
          {!isDependentField[field] && (<button onClick={()=>{
                let newMacro = {...this.props.card.macro||{}}, newAuto = {...this.props.card.auto||{}}
                delete newMacro[field]
                delete newAuto[field]
                this.updateCardProps ([['macro', newMacro], ['auto', newAuto]]);
          }}>(remove)</button>)}
          </div>
        <DebouncedTextarea 
              rows="2"
              cols="86"
              value={macro[field]} 
              placeholder={this.props.macroByName[field] || ''}
              onChange={(evt) => {
                let newMacro = {...this.props.card.macro||{}}, newAuto = {...this.props.card.auto||{}}
                newMacro[field] = evt.target.value
                delete newAuto[field]
                this.updateCardProps ([['macro', newMacro], ['auto', newAuto]]);
              }}
            />
        </div>))}
        <div className="cardcontrols">
          <label><input type="checkbox" checked={this.props.card.hide||0} onChange={(evt)=>this.updateCardState({hide:evt.target.checked})}/>Hide on map</label>
          <label><input type="checkbox" checked={this.props.bypassFilter||0} onChange={(evt)=>this.setFilterBypass(evt.target.checked)}/>Bypass filter</label>
        <button
          onClick={()=>this.props.owner.duplicateCard (this.props.card)}
          >Duplicate
          </button>
          {this.state.thinking ? (<span className="thinking">(...thinking...)</span>) : (<button
          onClick={()=>this.callBot (this.evalMacro())}
          >Suggest
          </button>)}
          <button
          onClick={()=>this.props.owner.deleteCard (this.props.card)}
          >Delete
          </button>
        </div>
      </div>
    )
  }

  expandMacroArgs() {
    return { text: this.props.globalPrompt, card: this.props.card, macroByName: this.props.macroByName, cardByID: this.props.cardByID }
  }

  evalMacro() {
    return expandMacros (this.expandMacroArgs());
  }

  componentDidUpdate() {
    setTimeout (() => window.scrollTo ({ top: this.state.scrollPosition }), 0)
  }

}

export default Card;
