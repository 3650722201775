import './App.css';
import React, { Component } from 'react';
import stringify from 'canonical-json';

import Card from './Card';
import Meter from './Meter';
import Macro from './Macro';
import Game from './Game';
import StageMap from './StageMap';
import DebouncedTextarea from './DebouncedTextarea';
import DebouncedInput from './DebouncedInput';
import { startStage } from './Dealer';
import { extend, download, readTextFile, cloneDeep, validateCharsToLower, makeObject, parseJwt, uint8ToBase64, expandMacros, getDependentFields } from './utils';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { CognitoIdentityProviderClient, UpdateUserAttributesCommand } from "@aws-sdk/client-cognito-identity-provider";


// Cognito hosted UI should be configured to give admin & profile scope (and email+openID+phone) and return a token
// The following URLs reflect this configuration:
const baseDomain = 'swipefic.com';
const webUrl = 'https://' + baseDomain + '/';
const authUrl = 'https://auth.' + baseDomain;
const loginUrl = authUrl + '/oauth2/authorize?client_id=3a4gessoggh8jji98kbu7fdhp3&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=' + encodeURIComponent(webUrl);
const logoutUrl = authUrl + '/logout?client_id=3a4gessoggh8jji98kbu7fdhp3&response_type=token&redirect_uri=' + encodeURIComponent(webUrl);



const cognitoRegion = 'us-east-1';
// const cognitoUserPoolID = 'us-east-1_Hw4eSS0Ck';

const apiUrl = 'https://api.' + baseDomain;
const decksApiUrl = apiUrl + '/decks';
const publicRulesApiUrl = apiUrl + '/public/rules';
const suggestApiUrl = apiUrl + '/suggest';

const deckApiUrl = (id) => decksApiUrl + '/' + id;
const publicRuleApiUrl = (id) => publicRulesApiUrl + '/' + id;

const playUrl = (id) => webUrl + 'play/index.html?rules=' + encodeURIComponent (publicRuleApiUrl (id));

const anonStageRegExp = /^card\d+$/;

class App extends Component {
  constructor(props) {
    super(props);

    const savedState = this.storedState()

    const cards = (savedState ? savedState.cards : props.cards) || [];
    const status = (savedState ? savedState.status : props.status) || '';
    const meters = (savedState ? savedState.meters : props.meters) || [];
    const macros = (savedState ? savedState.macros : props.macros) || [];
    const globalPrompt = (savedState ? savedState.globalPrompt : props.globalPrompt) || '';

    let stagePos = (savedState ? savedState.stagePos : props.stagePos) || {};
    if (!stagePos[this.startStage])
      stagePos[this.startStage] = { x: 0, y: 0 }

    const title = (savedState ? savedState.title : props.title) || '';
    const id = (savedState ? savedState.id : props.id) || '';

    const url = window.location.href;
    const query = url.split('#')[1];
    const queryParams = new URLSearchParams(query);

    let idTokenString = queryParams.get('id_token') || (savedState && savedState.idTokenString) || undefined;
    let idToken = (idTokenString && parseJwt(idTokenString)) || (savedState && savedState.idToken) || undefined;

    let accessTokenString = queryParams.get('access_token') || (savedState && savedState.accessTokenString) || undefined;
    let accessToken = (accessTokenString && parseJwt(accessTokenString)) || (savedState && savedState.accessToken) || undefined;

    const now = Date.now() / 1000;
    let logoutTime
    if (idToken)
      logoutTime = idToken.exp
    if (accessToken)
      logoutTime = typeof(logoutTime) === 'undefined' ? accessToken.exp : Math.min (accessToken.exp, logoutTime)
    if (typeof(logoutTime) !== 'undefined')
      setTimeout (() => this.eraseLoginState(), Math.max(logoutTime - now,0)*1000)

    const apiKey = (idToken && idToken['custom:apiKey']) || (savedState ? savedState.apiKey : props.apiKey) || '';

    this.state = { meters, status, cards, macros, globalPrompt, stagePos, title,
                   undo: [], redo: [],
                   filter: { bypass: {} },
                   mapView: {},
                   id, published: props.published,
                   accessTokenString, idTokenString, accessToken, idToken,
                   apiKey, lastSavedApiKey: apiKey };
 
    this.cognitoClient = new CognitoIdentityProviderClient ({ region: cognitoRegion });

    this.updateStoredState()
    window.location.hash = ''
  }

  get suggestApiUrl() { return suggestApiUrl }
  get decksApiUrl() { return decksApiUrl }
  deckApiUrl(id) { return deckApiUrl(id) }
  playUrl(id) { return playUrl(id) }

  componentDidMount() {
    this.setCurrentEditorSnapshotAndUpdateLocalStorage()
    this.refreshSavedDecks()
  }

  callSuggestAPI ({ prompt, type, pendingCallback, timeoutCallback, doneCallback }) {
    const app = this;
    const suggestApiUrl = app.suggestApiUrl;
    const apiKey = app.state.apiKey;
    if (!apiKey) {
      window.alert ("Please enter an API key under the Deck tab to generate suggestions")
      return
    }
    let thinkingTimer, stopThinking = () => {
      thinkingTimer && clearTimeout (thinkingTimer);
      timeoutCallback();
    };
    try {
      pendingCallback (() => {
        thinkingTimer = setTimeout (stopThinking, this.thinkingTimeout);
        fetch (suggestApiUrl,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': app.state.idTokenString,
                  },
                  body: JSON.stringify ({
                    type,
                    prompt,
                    apiKey,
                  })
                }).then ((response) => response.json())
                  .then (doneCallback)
      })
    } catch (err) {
      stopThinking()
    }
  }
  
  deleteDeck(id,title) {
    if (window.confirm('Are you sure you want to delete the deck "'+title+'" ? This cannot be undone.'))
      fetch(deckApiUrl(id),
        { method: 'DELETE',
          headers: { 'Content-Type': 'application/json',
                    'Authorization': this.state.idTokenString } })
          .then ((response) => response.json())
          .then (() => this.refreshSavedDecks())
          .catch ((err) => {
            console.error(err)
          })
  }

  loadDeck(id,title) {
    if (!this.deckNeedsSave() || window.confirm('Are you sure you want to delete the current deck and load "'+title+'" in its place? This cannot be undone.'))
      fetch(deckApiUrl(id),
        { method: 'GET',
          headers: { 'Content-Type': 'application/json',
                    'Authorization': this.state.idTokenString } })
          .then ((response) => response.json())
          .then ((savedDeck) => {
            this.fromJSON (extend ({}, savedDeck.rules, { title: savedDeck.title }), { id })
            this.markDeckAsSaved (true)
          })
          .catch ((err) => {
            console.error(err)
          })
  }

  makeTitleUnique (cb) {
    const versionRegex = /^(.*) \(([0-9]+)\)$/;
    let match;
    let title = this.state.title || ''; // eslint-disable-next-line
    while (this.state.savedDecks && this.state.savedDecks.filter((deck)=>deck.title===title).length) {
      match = title.match (versionRegex);
      if (match)
        title = match[1] + ' (' + (parseInt(match[2]) + 1) + ')'
      else 
        title = title + ' (1)'
    }
    this.setStateWithUndo ({ title }, cb)
  }

  saveDeck (id) {
    id = id || this.state.id || this.generateRandomDeckID()
    fetch(deckApiUrl(id),
      { method: 'PUT',
        body: JSON.stringify ({ title: this.state.title,
                                rules: {
                                  cards: this.state.cards,
                                  meters: this.state.meters,
                                  macro: this.state.macro,
                                  globalPrompt: this.state.globalPrompt,
                                  status: this.state.status,
                                  stagePos: this.state.stagePos,
                                },
                                published: this.state.published,
                              }),
        headers: { 'Content-Type': 'application/json',
                   'Authorization': this.state.idTokenString } })
        .then ((response) => {
          if (response.status !== 200)
            throw new Error ("Response code: " + response.status)
          this.setState({ id }, () => this.markDeckAsSaved())
        })
        .catch ((err) => {
          console.error(err)
          window.alert("Save failed")
        })
  }

  generateRandomDeckID() {
    const randomBytes = window.crypto.getRandomValues(new Uint8Array(12));
    return uint8ToBase64(randomBytes).replaceAll('/','@')
  }

  markDeckAsSaved (justLoaded) {
    this.setState ({ lastSavedSnapshot: this.state.snapshot,
                     lastSavedTime: !justLoaded && Date.now() },
                   () => {
                    this.updateStoredState()
                    this.refreshSavedDecks()
                   })
  }

  deckNeedsSave() {
    return this.state.cards.length && this.state.snapshot !== this.state.lastSavedSnapshot
  }

  refreshSavedDecks() {
    if (this.state.idTokenString)
      fetch(decksApiUrl,
      { method: 'GET',
        headers: { 'Content-Type': 'application/json',
                   'Authorization': this.state.idTokenString } })
        .then ((response) => response.status === 200 && response.json())
        .then ((savedDecks) => this.setState ({ savedDecks },
          () => {
            if (!savedDecks || !savedDecks.filter ((deck) => deck.id === this.state.id).length)
              this.setState ({ lastSavedSnapshot: undefined, lastSavedTime: undefined });
          }))
        .catch ((err) => {
          console.error(err)
        })
  }

  setApiKey (apiKey, userEntered) {
    this.setState ({ apiKey }, userEntered && (() => {
      this.updateStoredState();
      if (this.state.apiKey !== this.state.lastSavedApiKey && this.state.accessTokenString) {
        const params = {
          AccessToken: this.state.accessTokenString,
          UserAttributes: [
            {
              Name: 'custom:apiKey',
              Value: this.state.apiKey
            },
          ],
          ClientMetadata: { }
        };
        const command = new UpdateUserAttributesCommand(params);
        this.cognitoClient.send(command).then ((response) => {
          this.setState({ lastSavedApiKey: this.state.apiKey })
        })
      }
    }));
  }

  setState (state, cb) {
    super.setState (extend ({ focusCardID: undefined }, state), cb)
  }

  toJSON (meta) {
    let stagePos = {}
    this.getStages().forEach ((stage) => stagePos[stage] = this.state.stagePos[stage])
    return extend (
           { meters: this.state.meters,
             status: this.state.status,
             cards: this.state.cards,
             macros: this.state.macros,
             globalPrompt: this.state.globalPrompt,
             stagePos,
             published: this.state.published,
             title: this.state.title
            }, meta || {})
  }

  fromJSON (json, update, cb) {
    this.setState (extend ({  meters: json.meters || [],
                              status: json.status || '',
                              cards: json.cards || [],
                              macros: json.macros || [],
                              globalPrompt: json.globalPrompt || '',
                              stagePos: json.stagePos || {},
                              published: json.published,
                              title: json.title || '',
                              undo: [],
                              redo: [],
                              lastSavedSnapshot: undefined,
                              lastSavedTime: undefined
                            },
                            update || {}),
                   () => this.setCurrentEditorSnapshotAndUpdateLocalStorage (undefined, cb))
  }

  appToJSON (meta) {
    return this.toJSON (extend ({
      accessTokenString: this.state.accessTokenString,
      idTokenString: this.state.idTokenString,
      accessToken: this.state.accessToken,
      idToken: this.state.idToken,
      apiKey: this.state.apiKey,
      id: this.state.id,
    },
    meta || {}))
  }

  appFromJSON (json, update) {
    this.fromJSON (json,
                   extend ({ accessTokenString: json.accessTokenString,
                             idTokenString: json.idTokenString,
                             accessToken: json.accessToken,
                             idToken: json.idToken,
                             apiKey: json.apiKey,
                             id: json.id,
                           },
                           update || {}))
  }

  // getStageCount returns the number of times a stage is mentioned
  // As such it counts both left and right swipers separately, i.e. it double-counts cards that mention a stage twice
  // The exception is if the card is mirrored, in which case it will NOT count both swipers
  // This is in contrast to the card counts computed by getStageGraph(), which only ever count cards once for each outgoing edge
  getStageCount() {
    let count = {}
    count[this.startStage] = 1
    const addSwiper = (swiper) => {
      if (swiper) {
        if (swiper.stage)
          count[swiper.stage.toLowerCase()] = (count[swiper.stage.toLowerCase()] || 0) + 1
        if (swiper.push)
        count[swiper.push.toLowerCase()] = (count[swiper.push.toLowerCase()] || 0) + 1
      }
    }
    this.state.cards.forEach ((card) => {
      if (card.when)
        card.when.toLowerCase().split(',').forEach ((stage) => {
          if (stage)
            count[stage] = (count[stage] || 0) + 1
        })
      addSwiper (card.left)
      if (!card.mirror)
        addSwiper (card.right)
    })
    return count
  }

  getStages() {
    return Object.keys(this.getStageCount()).sort()
  }

  getStageGraph() {
    let node = {}, edge = {}
    node[this.startStage] = 0
    const addEdge = (src, dest, otherDest) => {
      src = src.toLowerCase()
      dest = dest.toLowerCase()
      node[dest] = node[dest] || 0
      if (src && dest !== otherDest) {
        edge[src] = edge[src] || {}
        edge[src][dest] = (edge[src][dest] || 0) + 1
        return dest
      }
      return undefined
    }
    const addSwiper = (src, swiper, otherDest) => {
      if (swiper) {
        if (swiper.stage)
          return addEdge (src, swiper.stage, otherDest)
        if (swiper.push)
          return addEdge (src, swiper.push, otherDest)
      }
      return undefined
    }
    const addOutgoing = (card, src) => {
      if (src) {
        node[src] = (node[src] || 0) + 1
        const leftDest = addSwiper (src, card.left)
        addSwiper (src, card.right, leftDest)
      }
    }
    const mapCards = this.state.cards.filter ((card) => !card.hide)
    mapCards.forEach ((card) => {
      const when = card.when && card.when.toLowerCase()
      if (when)
        when.split(',').forEach ((src) => addOutgoing (card, src))
    })
    const wildcards = mapCards.filter ((card) => !card.when), nodeNames = this.getStages()
    wildcards.forEach ((card) => nodeNames.forEach ((src) => addOutgoing (card, src)))
    let autoLayoutCount = this.state.autoLayoutCount || 0
    const nodes = nodeNames.map ((id) => {
      let thisNode = { id, cards: node[id] }
      if (this.state.stagePos[id])
        thisNode = extend (thisNode, { position: this.state.stagePos[id] })
      else {
        const n = ++autoLayoutCount
        thisNode = extend (thisNode, { position: this.newNodePosition(n), autoLayout: n })
      }
      return thisNode
    })
    let edges = []
    Object.keys(edge).forEach ((source) => {
      Object.keys(edge[source]).forEach ((target) => {
        edges.push ({ source, target, cards: edge[source][target] })
      })
    })
    nodes.forEach ((node) => {
      if (node.id === this.state.filter.stage)
        node.selected = true
      if (node.id === this.state.filter.edit)
        node.edit = true
      if (node.id === this.state.filter.source)
        node.sourceSelected = true
      if (node.id === this.state.filter.target)
        node.targetSelected = true
      if (this.stageNameLooksAnonymous(node.id) && node.cards <= 1)
        node.anonymous = true
    })
    edges.forEach ((edge) => {
      if ((edge.source === this.state.filter.source && !this.state.filter.target)
          || (edge.target === this.state.filter.target && !this.state.filter.source)
          || edge.source === this.state.filter.stage || edge.target === this.state.filter.stage)
        edge.animated = true
      if (edge.source === this.state.filter.source && edge.target === this.state.filter.target)
        edge.selected = edge.animated = true
    })
    return { nodes, edges }
  }

  newNodePosition (n) {
    let radius = 100, x0 = 0, y0 = 0
    let angle = Math.PI / 7
    // I have tried setting this from the viewport, but can't seem to decode React-Flow's viewport coordinates reliably... sigh
    // IH 8/16/2023
    const stage = this.state.filter.stage || this.state.filter.source || this.state.filter.target
    if (stage && this.state.stagePos[stage]) {
      x0 = this.state.stagePos[stage].x
      y0 = this.state.stagePos[stage].y
    }
    return { x: x0 - radius*Math.cos((n+1)*angle), y: y0 + radius*Math.sin((n+1)*angle) }
  }

  setNodePosition (id, pos) {
    this.setStateWithUndo (this.nodePositionUpdate (id, pos))
  }
  nodePositionUpdate (id, pos) {
    let update = {}
    update[id] = pos
    return { stagePos: extend ({}, this.state.stagePos, update) }
  }

  setEditNode (stage) {
    this.setState ({ filter: { ...this.state.filter, source: undefined, target: undefined, pageSize: undefined, stage, edit: stage } })
  }
  selectNode (stage) {
    this.setState ({ filter: { ...this.state.filter, source: undefined, target: undefined, pageSize: undefined, edit: undefined, stage } })
  }
  selectEdge (source, target) {
    this.setState ({ filter: { ...this.state.filter, stage: undefined, pageSize: undefined, edit: undefined, source, target }})
  }
  selectSource (source) {
    this.setState ({ filter: { ...this.state.filter, stage: undefined, target: undefined, pageSize: undefined, edit: undefined, source } })
  }
  selectTarget (target) {
    this.setState ({ filter: { ...this.state.filter, stage: undefined, source: undefined, pageSize: undefined, edit: undefined, target } })
  }
  unselectNodes() {
    // use a zero-delay timer to avoid conflicts in case we're selecting something else at the same time
    setTimeout (() => this.setState ({ filter: {...this.state.filter, pageSize: undefined, stage: undefined, edit: undefined } }), 0)
  }
  unselectEdges() {
    // use a zero-delay timer to avoid conflicts in case we're selecting something else at the same time
    setTimeout (() => this.setState ({ filter: {...this.state.filter, pageSize: undefined, source: undefined, target: undefined, edit: undefined } }), 0)
  }
  unselectAll() {
    this.setState ({ filter: {...this.state.filter, pageSize: undefined, source: undefined, target: undefined, stage: undefined, edit: undefined } })
  }
  addEdge (source, target) {
    this.newCardAndSetState (this.newEdgeCard (source, target), { filter: { ...this.state.filter, source, target, pageSize: undefined, stage: undefined, edit: undefined } })
  }
  newEdgeCard (source, target) {
    return { when: source, html: "Go to " + target + "?", left: { hint: "Stay" }, right: { hint: "Go", stage: target } }
  }
  newCardAndSetState (card, update, cb) {
    let filter = cloneDeep (this.state.filter)
    if (!this.filter(filter,card))
      filter.bypass[card.id] = true
    this.setStateWithUndo (extend (this.newCardUpdate (card), {filter}, update), cb)
  }
  addEdgeToNewNode (source, targetPos) {
    const target = this.uniqueStageName()
    this.newCardAndSetState (this.newEdgeCard (source, target),
                             this.nodePositionUpdate (target, targetPos),
                             () => setTimeout (() => this.setState ({
                                filter: { ...this.state.filter, 
                                          source, 
                                          target, 
                                          pageSize: undefined, 
                                          stage: undefined, 
                                          edit: undefined } }), 10))
  }
  addEdgeFromNewNode (target, sourcePos) {
    const source = this.uniqueStageName()
    this.newCardAndSetState (this.newEdgeCard (source, target),
                             this.nodePositionUpdate (source, sourcePos),
                             () => setTimeout (() => this.setState ({ 
                                filter: { ...this.state.filter, 
                                          source, 
                                          target, 
                                          pageSize: undefined, 
                                          stage: undefined, 
                                          edit: undefined } }), 10))
  }

  deleteNode (stage) {
    if (window.confirm ("Delete all cards involving stage '" + stage + "' ?")) {
      const match = this.filter.bind (this, { stage }), notMatch = (card) => !match(card);
      let stagePos = extend ({}, this.state.stagePos)
      delete stagePos[stage]
      this.setStateWithUndo ({ cards: this.state.cards.filter (notMatch), stagePos })
    }
  }

  deleteEdge (source, target) {
    if (window.confirm ("Delete all cards that transition from stage '" + source + "' to stage '" + target + "' ?")) {
      const match = this.filter.bind (this, { source, target }), notMatch = (card) => !match(card);
      this.setStateWithUndo ({ cards: this.state.cards.filter (notMatch) })
    }
  }

  renameNode (oldStage, newStage, nodeNames) {
    if (!newStage || nodeNames.indexOf(newStage) > -1)
      return
    if (oldStage !== oldStage.toLowerCase() || newStage !== newStage.toLowerCase())
      throw new Error ("in renameNode: old and new stage names must be lower case")
    function renameProp (obj, prop) {
      if (obj[prop] && obj[prop].toLowerCase() === oldStage)
        obj[prop] = newStage
    }
    function renameNodeInSwiper (swiper) {
      if (swiper) {
        renameProp (swiper, 'stage')
        renameProp (swiper, 'push')
      }
    }
    const cards = cloneDeep(this.state.cards).map ((card) => {
      if (card.when)
        card.when = card.when.split(',').map ((src) => src && src.toLowerCase() === oldStage ? newStage : src).join(',')
      renameNodeInSwiper (card.left)
      renameNodeInSwiper (card.right)
      return card
    })
    let { filter } = this.state
    renameProp (filter, 'edit')
    renameProp (filter, 'stage')
    renameProp (filter, 'source')
    renameProp (filter, 'target')
    let stagePos = cloneDeep (this.state.stagePos)
    stagePos[newStage] = stagePos[oldStage]
    delete stagePos[oldStage]
    this.setStateWithUndo ({ cards, filter, stagePos })
  }

  storedState() {
    let s
    try {
      let store = window.localStorage.getItem (this.localStorageKey)
      s = store && JSON.parse (store)
    } catch (e) {
      this.deleteStoredState()
    }
    return s
  }

  deleteStoredState() {
    window.localStorage.removeItem (this.localStorageKey)
  }

  resetAppState() {
    this.deleteStoredState()
    window.location.href = '/'
  }

  eraseLoginState() {
    const undefineTokens = { idToken: undefined, accessToken: undefined, accessTokenString: undefined, idTokenString: undefined, apiKey: undefined };
    this.updateStoredState (undefineTokens)
    return this.setState (undefineTokens)
  }

  logout() {
    if (!this.deckNeedsSave() || window.confirm("You have unsaved changes. Are you sure you want to log out? Your changes will be lost!")) {
      this.deleteStoredState()
      window.location.href = logoutUrl  // hacky way to force token invalidation. Better: https://oauth.net/2/token-revocation/
    }
  }

  newDeck() {
    let stagePos = {}
    stagePos[this.startStage] = { x: 0, y: 0 }
    this.setStateWithUndo ({ id: undefined,
                             meters: [], status: '', cards: [], macros: [], globalPrompt: '', title: '', stagePos,
                             mapView: {},
                             filter: { bypass: {} } });
  }

  updateStoredState (update) {
    window.localStorage.setItem (this.localStorageKey,
      stringify (extend (this.appToJSON (update))))
  }

  uniqueID (prefix, n, array, idPropName) {
    if (typeof(n) === 'undefined')
      n = 1;
    // eslint-disable-next-line
    while (array.filter ((item) => item[idPropName].toLowerCase() === prefix.toLowerCase() + n).length)
      ++n;
    return prefix + n;
  }

  uniqueMeterName (prefix, n) { return this.uniqueID (prefix || 'meter', n, this.state.meters, 'name') }
  uniqueMacroName (prefix, n) { return this.uniqueID (prefix || 'macro', n, this.state.macros, 'name') }
  uniqueCardID (prefix, n) { return this.uniqueID (prefix || 'card', n, this.state.cards, 'id') }
  uniqueStageName (prefix, n) { return this.uniqueID (prefix || 'stage', n, this.getStageGraph().nodes, 'id') }

  stageNameLooksAnonymous (stage) { return anonStageRegExp.test(stage) }

  newCard (card, cb) {
    this.setStateWithUndo(this.newCardUpdate(card || {}),cb)
  }
  newCardUpdate (card) {
    const id = this.uniqueCardID()
    return { cards: [extend({ id, html: id, limit: '', created: Date.now() }, card)].concat(this.state.cards),
             focusCardID: id }
  }

  newCardFromFilter() {
    const filter = this.state.filter
    let card = { when: this.startStage, right: { hint: "OK" } }
    if (filter.stage)
      card.when = filter.stage
    else if (filter.source || filter.target) {
      if (filter.source)
        card.when = filter.source
      if (filter.target)
        card.right = { hint: "Go", stage: filter.target }
    }
    this.newCard (card)
  }

  currentEditorSnapshot (meta) {
    return stringify (this.toJSON (meta))
  }

  setCurrentEditorSnapshotAndUpdateLocalStorage (snapshot, cb) {
    snapshot = snapshot || this.currentEditorSnapshot()
    this.updateStoredState()
    this.setState ({ snapshot }, cb)
  }
  
  setStateWithUndo (update, cb) {
    const oldUndo = this.state.undo, lastUndo = oldUndo.length ? oldUndo[oldUndo.length-1] : undefined
    const snapshot = stringify (this.toJSON())
    const undo = oldUndo.concat (lastUndo === snapshot ? [] : [snapshot]);
    this.setStateAndScroll (extend (update, { undo, redo: [] }), () => this.setCurrentEditorSnapshotAndUpdateLocalStorage (snapshot, cb))
  }

  setStateAndScroll (update, cb) {
    this.setState (extend (update, { scrollPosition: window.pageYOffset }), cb)
  }

  undo() {
    let undo = this.state.undo.slice(0), popped = undo.pop(), redo = this.state.redo.concat ([stringify (this.toJSON())])
    this.fromJSON (JSON.parse(popped), { undo, redo })
  }

  redo() {
    let redo = this.state.redo.slice(0), popped = redo.pop(), undo = this.state.undo.concat ([stringify (this.toJSON())])
    this.fromJSON (JSON.parse(popped), { undo, redo })
  }

  getMacroByName() {
    let result = {}
    if (this.state.macros)
      this.state.macros.forEach ((macro) => result[macro.name.toLowerCase()] = macro.text)
    return result
  }

  getCardByID() {
    let result = {}
    this.state.cards.forEach ((card) => result[card.id] = card)
    return result
  }

  render() {
    const meterNames = this.state.meters.map ((meter) => meter.name);
    
    const filter = this.state.filter;
    const cardsMatchingStageOrContent = this.state.cards.filter (this.filter.bind(this,{stage:filter.stage,source:filter.source,target:filter.target,content:filter.content}));
    let typeCount = {}
    this.state.cards.forEach ((card) => typeCount[card.type || ''] = 0)
    cardsMatchingStageOrContent.forEach ((card) => ++typeCount[card.type || ''])
    const types = Object.keys(typeCount).sort(), filterType = filter.type || makeObject(types.map((t)=>[t,true]))
    const allTypesSelected = !types.filter((t)=>!filterType[t]).length, allTypesUnselected = !types.filter((t)=>filterType[t]).length
    const disableTypeControls = types.length === 1 && allTypesSelected
    const noTypesAvailable = !cardsMatchingStageOrContent.length
    const matchingCards = this.state.cards.filter (this.filter.bind(this,this.state.filter))
      .sort (this.sortComparison());
    const pagedCards = matchingCards.slice (0, this.pageSize())
    const moreCardsAvailable = pagedCards.length < matchingCards.length
    const nMatch = matchingCards.length, nTotal = this.state.cards.length, notShown = nTotal - nMatch;

    const stageCount = this.getStageCount(), macroByName = this.getMacroByName(), cardByID = this.getCardByID();
    const dependentFields = getDependentFields ({ text: this.state.globalPrompt, cardByID, macroByName })
    const gotMacros = dependentFields.length > 0 || this.state.cards.filter((card)=>card.macro&&Object.keys(card.macro)).length > 0
    const gotAuto = this.state.cards.filter((card)=>card.auto&&Object.keys(card.auto).length).length > 0

    return (
      <div className="App">
        <div className="column1">
        <Game owner={this} game={this.state.game} cards={this.state.cards} status={this.state.status} meters={this.state.meters}/>
        <Tabs>
  <TabList>
  <div className="undocontrols">
            <button disabled={this.state.redo.length===0} onClick={() => this.redo()}>Redo</button>
            <button disabled={this.state.undo.length===0} onClick={() => this.undo()}>Undo</button>
    </div>
    <Tab>Cards</Tab>
    <Tab>Meters</Tab>
    <Tab>Status</Tab>
    <Tab>Macros</Tab>
  </TabList>

  <TabPanel>
  <div className="cards">
            <div className="controls">
              <div className="leftcontrols">
            <button
            onClick={() => this.newCardFromFilter()}
            >New card</button>
            {gotMacros && (<><label><input type="checkbox" checked={!!this.state.showMacros} onChange={(evt)=>this.setState({showMacros:evt.target.checked})}/>Show macros</label>
            {this.state.showMacros && gotAuto && (<label><input type="checkbox" checked={!this.state.hideAuto} onChange={(evt)=>this.setState({hideAuto:!evt.target.checked})}/>Include implicit macros</label>)}</>)}
            </div>
            <div className="cardcount">
              Showing {moreCardsAvailable && ("first "+pagedCards.length+" of ")}{nMatch}/{nTotal} card{nTotal===1?"":"s"} passing filter
            </div>
            </div>
              <div className="cardlist scrollable">
              {pagedCards.map ((card) => {
                const inPlay = this.state.game && this.state.game.rawCardWeightByID && typeof(this.state.game.rawCardWeightByID[card.id]) !== 'undefined';
                return (<Card
                  card={card}
                  meters={meterNames}
                  globalPrompt={this.state.globalPrompt}
                  cardWeight={inPlay && this.state.game.cardWeightByID}
                  rawCardWeight={inPlay && this.state.game.rawCardWeightByID}
                  turnsSinceCardPlayed={inPlay && this.state.game.turnsSinceCardPlayed}
                  turnsSinceTypePlayed={inPlay && this.state.game.turnsSinceTypePlayed}
                  turnsByCard={inPlay && this.state.game.turnsByCard}
                  turnsByType={inPlay && this.state.game.turnsByType}
                  turnsByStageCurrent={inPlay && this.state.game.turnsByStageCurrent}
                  turnsByStageTotal={inPlay && this.state.game.turnsByStageTotal}
                  currentStage={inPlay && this.state.game.stage}
                  dealtPriority={inPlay && this.state.game.card ? (this.state.game.card.priority||0) : undefined}
                  played={inPlay && this.state.game.card && this.state.game.card.id === card.id}
                  bypassFilter={this.state.filter.bypass[card.id] || 0}
                  owner={this}
                  stageCount={stageCount}
                  macroByName={macroByName}
                  cardByID={cardByID}
                  autoFocus={this.state.focusCardID === card.id}
                  showMacros={this.state.showMacros}
                  showAuto={!this.state.hideAuto}
                  key={"card-"+card.id}/>)
                }
              )}
              <div className="morecards">{matchingCards.length > this.pageSize() && (<button onClick={()=>this.showMoreCards()}>Show more cards</button>)}</div>
              <div>
              {notShown > 0 && (<div className="notshown">{notShown} {nMatch?"more ":""}card{notShown>1?"s do":" does"} not match filter and {notShown>1?"are":"is"} not shown {allTypesUnselected && " (try selecting some types in the filter)"}</div>)}
              </div>
              </div>
            </div>

  </TabPanel>

  <TabPanel>
  <div className="meters">
            <div className="controls">
            <button
            onClick={() => this.setStateWithUndo({meters:this.state.meters.concat([{name:this.uniqueMeterName()}])})}
            >New meter</button></div>
            <div className="meterlist">
              {this.state.meters.map ((meter, n) => (<Meter meter={meter} owner={this} iconName={meter.name} key={"meter-"+n}/>))}
            </div>
          </div>


  </TabPanel>

  <TabPanel>
  <div className="status">
              <div className="text">
                <DebouncedTextarea 
                    cols="90"
                    rows="10"
                    value={this.state.status || ''} 
                    placeholder="Status message goes here"
                    onChange={(evt) => this.setStateWithUndo (extend (this.state, { status: evt.target.value }))} 
                  />
              </div>
            </div>

  </TabPanel>

  <TabPanel>
  <div className="macros">
            <div className="controls">
            <button
            onClick={() => this.setStateWithUndo({ macros: this.state.macros.concat([{name:this.uniqueMacroName()}])})}>
              New macro</button>
            <div className="macro-filter">
              Search macros:
            <DebouncedInput
              size="40"
              placeholder="Search text"
              value={this.state.macroFilter || ''}
              onChange={(evt)=>this.setState({macroFilter:evt.target.value})}
              />
            </div>
            <DebouncedInput
            value={this.state.expansionCardID||''}
            placeholder="Card ID for expansions (optional)"
            size={30}
            onChange={(evt)=>this.setState({expansionCardID:evt.target.value})}
          />
            </div>
            <div className="macrolist scrollable">
            <div className="Macro">
            <div className="label">Main AI prompt for suggestions:</div>
                <DebouncedTextarea 
                    cols="80"
                    rows="10"
                    value={this.state.globalPrompt || ''} 
                    placeholder="AI prompt goes here"
                    onChange={(evt) => this.setStateWithUndo (extend (this.state, { globalPrompt: evt.target.value }))} 
                  />
              <div className="expansion" dangerouslySetInnerHTML={{__html:typeof(this.state.globalPromptExpansion)==='undefined'?'':(this.state.globalPromptExpansion||'<i>Expands to empty string</i>')}}/>
                <div className="controls">
                <button
                  onClick={()=>this.setState({globalPromptExpansion:expandMacros({text:this.state.globalPrompt||'',card:(this.state.expansionCardID&&cardByID[this.state.expansionCardID])||undefined,macroByName,cardByID})})}
                >Expand
                </button>
                </div>
                </div>
              {this.state.macros
              .filter ((macro) => !this.state.macroFilter || (macro.name.toLowerCase().includes(this.state.macroFilter.toLowerCase()) || (macro.text||'').toLowerCase().includes(this.state.macroFilter.toLowerCase())))
              .map ((macro) => (<Macro macro={macro} card={(this.state.expansionCardID&&cardByID[this.state.expansionCardID])||undefined} macroByName={macroByName} cardByID={cardByID} owner={this} key={"macro-"+macro.name}/>))}
            </div>
              </div>

  </TabPanel>

</Tabs>
         </div>

          <div className="column2">
          <Tabs>
  <TabList>
    <div className="authcontrols">
      {this.state.idToken
      ? (<span>{this.state.idToken['cognito:username']} <a href={logoutUrl} onClick={(evt)=>{
          evt.preventDefault()
          this.logout()
      }}>(logout)</a></span>)
      : (<a href={loginUrl}>Login</a>)}
    </div>
    <Tab>Map</Tab>
    <Tab>Filter</Tab>
    <Tab>Deck</Tab>
  </TabList>

  <TabPanel>
    <StageMap
      owner={this} 
      stageGraph={this.getStageGraph()}
      defaultViewport={this.state.mapView.viewport}
      fitView={!this.state.mapView.viewport}
      onViewportChange={(viewport) => {
        if (!this.state.mapView.viewport || stringify(viewport) !== stringify(this.state.mapView.viewport))
          this.setState ({ mapView: {...this.state.mapView, viewport }})
      }}
      onRender={(info) => {
        const { dim, autoLayout } = info
        let update
        if (!this.state.mapView.dim || stringify(dim) !== stringify(this.state.mapView.dim))
          update = { mapView: {...this.state.mapView, dim }}
        if (autoLayout.length) {
          let stagePos = {...this.state.stagePos}, autoLayoutCount = this.state.autoLayoutCount || 0
          autoLayout.forEach ((node) => {
            stagePos[node.id] = { x: node.position.x, y: node.position.y }
            autoLayoutCount = Math.max (autoLayoutCount, node.autoLayout)
          })
          update = {...update||{}, autoLayoutCount, stagePos}
        }
        if (update)
          this.setState (update)
      }}
     />
  </TabPanel>

  <TabPanel>
    <div className="filter">
    <div className="filterhelp">Show only cards associated with a particular stage:</div>
    <div> 
        <DebouncedInput size="24"
          placeholder='Stage'
          value={this.state.filter.stage || ''}
          onChange={(evt)=>this.setState ({ filter: {...this.state.filter, stage: evt.target.value, pageSize: undefined, edit: undefined }} )}
          validateChars={validateCharsToLower}
          label="(includes both incoming &amp; outgoing transitions)"
        />
      </div>
      <div className="filterhelp">Show only cards associated with a particular transition between stages:</div>
      <div> 
        <DebouncedInput size="24"
          placeholder='Starting stage'
          value={this.state.filter.source || ''}
          onChange={(evt)=>this.setState ({filter:extend(this.state.filter,{source:evt.target.value, pageSize: undefined, edit: undefined})})}
          validateChars={validateCharsToLower}
        />
        <span className="label">&rarr;</span>
        <DebouncedInput size="24"
          placeholder='Ending stage'
          value={this.state.filter.target || ''}
          onChange={(evt)=>this.setState ({filter:extend(this.state.filter,{target:evt.target.value, pageSize: undefined, edit: undefined})})}
          validateChars={validateCharsToLower}
        />
      </div>
      <div className="filterhelp">Show only cards including specific content, hint, or preview text:</div>
      <div> 
        <DebouncedInput size="48"
          placeholder='Content'
          value={this.state.filter.content || ''}
          onChange={(evt)=>this.setState ({filter:extend(this.state.filter,{content:evt.target.value, pageSize: undefined, edit: undefined})})}
        />
      <label><input type="checkbox" checked={!this.state.filter.caseSensitive} onChange={(evt)=>this.setState({filter:extend({},this.state.filter,{caseSensitive:!evt.target.checked})})}/>Ignore case</label>
      </div>
      <div className="hidefilter"><label><input type="checkbox" checked={this.state.filter.showHidden || 0} onChange={(evt)=>this.setState({filter:extend({},this.state.filter,{showHidden:evt.target.checked})})}/>Show only cards that are hidden on the map</label></div>
      <div className="filterhelp">Show only cards of a particular type:</div>
      <div className="typefilter"><div className="types">
      {types.sort().map ((type) => (<label key={"type-"+type}><input type="checkbox" checked={filterType[type]||false} disabled={!typeCount[type] || disableTypeControls}
      onChange={(evt)=>{let newFilterType=extend({},filterType);newFilterType[type]=evt.target.checked;this.setState({filter:extend({},filter,{type:newFilterType})})}}/>
      {type||"No type"} ({typeCount[type]})</label>))}
      </div>
      {!disableTypeControls && (<div className="typebuttons">
      <div><button onClick={()=>this.setState({filter:extend({},filter,{type:makeObject(types.map((t)=>[t,true]))})})} disabled={allTypesSelected||noTypesAvailable}>Select all</button></div>
      <div><button onClick={()=>this.setState({filter:extend({},filter,{type:makeObject(types.map((t)=>[t,false]))})})} disabled={allTypesUnselected||noTypesAvailable}>Unselect all</button></div>
      </div>)}</div>
      <div className="filterhelp">Filter by current playthrough:</div>
      <div className="gamefilter"><label><input type="checkbox" disabled={!this.state.game} checked={this.state.filter.idPlayed || 0} onChange={(evt)=>this.setState({filter:extend({},this.state.filter,{idPlayed:evt.target.checked})})}/>Show only cards visited during the current playthrough</label></div>
      <div className="gamefilter"><label><input type="checkbox" disabled={!this.state.game} checked={this.state.filter.playable || 0} onChange={(evt)=>this.setState({filter:extend({},this.state.filter,{playable:evt.target.checked})})}/>Show only cards eligible for play on the current turn</label></div>
      <div className="gamefilter"><label><input type="checkbox" disabled={!this.state.game} checked={this.state.filter.currentCard || 0} onChange={(evt)=>this.setState({filter:extend({},this.state.filter,{currentCard:evt.target.checked})})}/>Show only current card</label></div>
      <div className="bypass">
        <div><select value={this.state.filter.hideAll?"1":""} onChange={(evt)=>this.setState({filter:{...this.state.filter,hideAll:evt.target.value}})}><option value="">Always</option><option value="1">Only</option></select> show the following cards:</div>
        <div className="bypass-list">{Object.keys(this.state.filter.bypass).filter((id)=>this.state.filter.bypass[id]).sort().map((id)=>(<span className="filterid" key={"bypass-"+id}>{id}<button onClick={()=>this.setFilterBypass(id,false)}>(x)</button></span>))}</div>
      <div className="add-bypass"><input type="text" id="addBypass" placeholder="Card ID (e.g. 'card1')"/><button onClick={()=>{const elt=document.getElementById('addBypass'),id=elt.value;elt.value='';if(id&&this.state.cards.findIndex((c)=>c.id===id)>=0)this.setFilterBypass(id,true)}}>Add</button></div>
      </div>
      <div className="pagination">
        Show only the first
        <DebouncedInput size="6" value={this.pageSize()}
        onChange={(evt)=>this.setState({filter:extend({},this.state.filter,{pageSize:parseInt(evt.target.value)})})} />
        cards matching the above conditions
      </div>
      <fieldset className="filterhelp">
        <legend>Card sort order</legend>
        <div><label><input type="radio" value="" checked={!this.state.filter.sort} onChange={()=>this.setState({filter:extend(this.state.filter,{sort:undefined})})}/>Default: group by parent and creation order</label></div>
        <div><label><input type="radio" value="date" checked={this.state.filter.sort === 'date'} onChange={()=>this.setState({filter:extend(this.state.filter,{sort:'date'})})}/>Most recently created</label></div>
        <div><label><input type="radio" value="edit" checked={this.state.filter.sort === 'edit'} onChange={()=>this.setState({filter:extend(this.state.filter,{sort:'edit'})})}/>Most recently edited</label></div>
        <div><label><input type="radio" value="weight" checked={this.state.filter.sort === 'weight'} onChange={()=>this.setState({filter:extend(this.state.filter,{sort:'weight'})})}/>Weight and priority as shown on card</label></div>
        <div><label><input type="radio" value="eval" disabled={!this.state.game} checked={this.state.filter.sort === 'eval'} onChange={()=>this.setState({filter:extend(this.state.filter,{sort:'eval'})})}/>Weight and priority as calculated for current turn</label></div>
        <div><label><input type="radio" value="turn" disabled={!this.state.game} checked={this.state.filter.sort === 'turn'} onChange={()=>this.setState({filter:extend(this.state.filter,{sort:'turn'})})}/>Most recently played in current playthrough</label></div>
      </fieldset>
      <div className="filterhelp"><button onClick={()=>this.setState({filter:{bypass:{}}})}>Reset all filters</button></div>
    </div>
  </TabPanel>

  <TabPanel>
    <div className="deckstab">
    <div className="iocontrols">
      <div className="titlecontrols">
        <div className="titlehelp">Title</div>
        <div> 
          <DebouncedInput size="80"
            placeholder='Title of this deck'
            value={this.state.title || ''}
            onChange={(evt)=>this.setStateWithUndo ({ title: evt.target.value })}
          />
        </div>
        </div>
        {this.state.idToken && (<>
          <div>
            <button disabled={!this.deckNeedsSave()} onClick={()=>this.saveDeck()}>Save</button>
            {!this.deckNeedsSave() && this.state.lastSavedTime && (<span className="timestamp">Saved {new Date(this.state.lastSavedTime).toString()}</span>)}
          </div>
          <div>
            <button onClick={()=>this.makeTitleUnique (() => this.saveDeck(this.generateRandomDeckID()))}>Save as copy</button>
          </div>
          <div>
            <button onClick={(evt)=>this.setStateWithUndo({published:!this.state.published},()=>this.saveDeck())}>
              {this.state.published?"Unpublish":"Publish"}
              </button>
              {this.state.published && this.state.id && (<span className="playlink">(currently playable <a target="_blank" rel="noreferrer" href={this.playUrl(this.state.id)}>here</a>)</span>)}

          </div>
        </>)}
     <div><button
          onClick={() => download (JSON.stringify (this.toJSON()), this.gameFilename, 'text/plain')}
        >Export to JSON</button></div>
      <div><button
          onClick={() => readTextFile ((text) => this.fromJSON (JSON.parse (text)))}
        >Import from JSON</button></div>
      <div><button
          onClick={() => { this.resetAppState(); }}
        >Reset app</button></div>
      <div><button
          onClick={() => { this.newDeck(); }}
        >New deck</button></div>

      <div className="usercontrols">
        <h2>{this.state.idToken ? ('User ' + this.state.idToken['cognito:username']) : 'Guest user'}</h2>
      <div> <label>OpenAI API key:</label>
        <DebouncedInput size="60"
          type={this.state.showApiKey?'text':'password'}
          placeholder='OpenAI API key'
          value={this.state.apiKey || ''}
          onChange={(evt)=>this.setApiKey (evt.target.value, true)}
          validateChars={(x)=>x.replaceAll(' ','')}
        />
        <label><input type="checkbox" checked={!!this.state.showApiKey} onChange={(evt)=>this.setState({showApiKey:evt.target.checked})}/>Show</label>
      </div>
      </div>
      </div>
      {this.state.savedDecks && (<div className="decks">
        <h3>{this.state.savedDecks.length ? ("Saved decks: " + this.state.savedDecks.length + " ") : "No saved decks "}
          <button onClick={()=>this.refreshSavedDecks()}>(refresh)</button>
          </h3>
       <div className="decklist">
        <table>
          <thead><tr><th>Title</th><th>Cards</th><th>Last modified</th><th>Published?</th><th>Actions</th></tr></thead>
          <tbody>
            {this.state.savedDecks && this.state.savedDecks
                .map ((deck,n) => (<tr key={'deck-'+n} className={"deckrow "+(deck.id===this.state.id?"selected":"")}>
              <td><button onClick={()=>this.loadDeck(deck.id,deck.title)}>{deck.title}</button></td>
              <td><span className="decksize">{deck.cards}</span></td>
              <td><span className="timestamp">{new Date(deck.lastModified).toString()}</span></td>
              <td><input type="checkbox" checked={deck.published||false} disabled={deck.id!==this.state.id} onChange={(evt)=>{
                this.setStateWithUndo({published:evt.target.checked},()=>this.saveDeck())
              }}/></td>
              <td>
                {deck.published && (<><a target="_blank" rel="noreferrer" href={this.playUrl(deck.id)}>Play</a>,</>)}
                <button onClick={()=>this.loadDeck(deck.id,deck.title)}>Load</button>, 
                {deck.id===this.state.id && (<><button onClick={()=>this.saveDeck()}>Save</button>,</>)}
                <button onClick={()=>this.deleteDeck(deck.id,deck.title)}>Delete</button>
                </td>
              </tr>))}
          </tbody>
        </table>
       </div>
      </div>)}
    </div>
  </TabPanel>

  </Tabs>
      </div>
    </div>
    )
  }

  componentDidUpdate() {
    setTimeout (() => window.scrollTo ({ top: this.state.scrollPosition }), 0)
  }

  setMeterState (name, meter) {
    const meters = this.state.meters.map ((m) => m.name === name ? meter : m);
    this.setStateWithUndo ({meters})
  }

  setMacroState (name, macro) {
    const macros = this.state.macros.map ((p) => p.name.toLowerCase() === name.toLowerCase() ? macro : p);
    this.setStateWithUndo ({macros})
  }

  setCardState (id, card, appUpdate, cb) {
    const oldCard = this.state.cards.find ((c) => c.id === id);
    const cards = this.state.cards.map ((c) => c.id === id ? card : c);
    const newState = { cards, ...appUpdate || {} };
    if (this.filter (this.state.filter, oldCard) && !this.filter (this.state.filter, card)) {
      newState.filter = {...this.state.filter,bypass:{...this.state.filter.bypass}}
      newState.filter.bypass[id] = true
    }
    this.setStateWithUndo (newState, cb)
  }

  duplicateCard (card) {
    const pos = this.state.cards.findIndex ((c) => c.id === card.id);
    if (pos >= 0)
      this.spliceCard (pos, card, extend (cloneDeep(card), { id: this.uniqueCardID() }));
  }

  oppositeDir (dir) {
    return dir === 'left' ? 'right' : 'left'
  }

  insertSwiperCard (card, dir, mirror) {
    const swiper = card[dir] || {};
    const newStage = this.uniqueCardID();
    const stageType = swiper.stage ? 'stage' : (swiper.push ? 'push' : (swiper.pop ? 'pop' : ''));
    let newSwiper = cloneDeep(swiper), newCardSwiper = {};
    if (swiper.reward)
      newCardSwiper.reward = cloneDeep (swiper.reward);
    delete newSwiper.reward;
    if (stageType === 'push') {
      delete newSwiper[stageType];
      newSwiper.push = newStage;
      newCardSwiper.stage = swiper[stageType];
    } else if (stageType) {
      delete newSwiper[stageType];
      newSwiper.stage = newStage;
      newCardSwiper[stageType] = swiper[stageType];
    } else {
      newSwiper.push = newStage;
      newCardSwiper.pop = 1;
    }
    let cardUpdate = {};
    cardUpdate[dir] = newSwiper;
    if (mirror)
      cardUpdate[this.oppositeDir(dir)] = cloneDeep(newSwiper);

    const macro = { previous_prompt: '@' + card.id + '$?',
                    previous_response:  '@' + card.id + '$' + (dir === 'left' ? '<' : '>'),
                    transcript: '@' + card.id + '$transcript\nNarrator: $previous_prompt\nPlayer: $previous_response\n',
                  };
    let auto = {};
    Object.keys(macro).forEach ((name) => auto[name] = true);

    const newCard = { id: newStage,
                      parent: card.id,
                      when: newStage,
                      icon: card.icon,
                      html: swiper.preview || newStage,
                      macro,
                      auto,
                      left: newCardSwiper,
                      right: cloneDeep(newCardSwiper),
                    }

    const pos = this.state.cards.findIndex ((c) => c.id === card.id);
    this.spliceCard (pos, extend (cloneDeep(card), cardUpdate), newCard);
  }

  spliceCard (pos, parentCard, childCard) {
    const newCards = this.state.cards.toSpliced (pos, 1, parentCard, childCard)
    let filter = cloneDeep (this.state.filter)
    if (!this.filter (filter, childCard))
      filter.bypass[childCard.id] = true
    this.setStateWithUndo ({ cards: newCards, focusCardID: childCard.id, filter })
  }

  deleteCard (card) {
    let filter = cloneDeep (this.state.filter)
    delete filter.bypass[card.id]
    this.setStateWithUndo ({ cards: this.state.cards.filter ((c) => c.id !== card.id), filter })
  }

  deleteMeter (meter) {
    this.setStateWithUndo ({ meters: this.state.meters.filter ((m) => m.name !== meter.name) })
  }
  
  deleteMacro (macro) {
    this.setStateWithUndo ({ macros: this.state.macros.filter ((p) => p.name.toLowerCase() !== macro.name.toLowerCase()) })
  }

  matchesSource (card, stage) {
    return !card.when || card.when.toLowerCase().split(',').indexOf(stage.toLowerCase()) >= 0;
  }
  matchesSwiperTarget (swiper, stage) {
    return swiper && ((swiper.stage && swiper.stage.toLowerCase() === stage) || (swiper.push && swiper.push.toLowerCase() === stage.toLowerCase()));
  }
  matchesTarget (card, stage) {
    return this.matchesSwiperTarget (card.left, stage) || this.matchesSwiperTarget (card.right, stage);
  }
  matchesContent (card, content, caseSensitive) {
    return card.html && (caseSensitive ? card.html.includes(content) : card.html.toLowerCase().includes(content.toLowerCase()))
  }
  matchesSwiperContent (swiper, content, caseSensitive) {
    return swiper && 
            ((swiper.hint && (caseSensitive ? swiper.hint.includes(content) : swiper.hint.toLowerCase().includes(content.toLowerCase()))) || 
             (swiper.preview && (caseSensitive ? swiper.preview.includes(content) : swiper.preview.toLowerCase().includes(content.toLowerCase()))))
  }
  filter (filter, card) {
    let match = true
    if (!(filter.bypass && filter.bypass[card.id])) {
      if (filter.hideAll)
        match = false
      if (filter.stage)
        match = match && (this.matchesSource(card,filter.stage) || this.matchesTarget(card,filter.stage))
      if (filter.source)
        match = match && this.matchesSource(card,filter.source)
      if (filter.target)
        match = match && this.matchesTarget(card,filter.target)
      if (filter.content)
        match = match && (this.matchesContent(card,filter.content,filter.caseSensitive) || this.matchesSwiperContent(card.left,filter.content,filter.caseSensitive) || this.matchesSwiperContent(card.right,filter.content,filter.caseSensitive))
      if (filter.type)
        match = match && filter.type[card.type || '']
      if (this.state.game) {
        if (filter.idPlayed)
          match = match && typeof(this.state.game.turnsSinceCardPlayed[card.id]) !== 'undefined'
        if (filter.playable)
          match = match && this.state.game && this.state.game.cardWeight[card.id]
        if (filter.currentCard)
          match = match && this.state.game && this.state.game.card && this.state.game.card.id === card.id
      }
      if (filter.showHidden)
        match = match && card.hide
    }
    return match
  }
  sortComparison() {
    const sort = this.state.filter.sort;
    const nCards = this.state.cards.length;
    const rank = makeObject (this.state.cards.map ((c,n) => [c.id,n]));
    const cardWeight = this.state.game && this.state.game.cardWeight;
    let turns;
    if (this.state.game) {
      turns = extend ({}, this.state.game.turnsSinceCardPlayed);
      if (this.state.game.card)
        turns[this.state.game.card.id] = -1;  // hack so that currently dealt card appears at top, even though it technically hasn't been played at the point turnsSinceCardPlayed refers to
    }
    const rankSort = (a,b) => rank[a.id] - rank[b.id];
    const weightSort = (a,b) => (parseFloat(b.priority || 0) - parseFloat(a.priority || 0)) || (parseFloat(b.weight || 0) - parseFloat(a.weight || 0)) || rankSort(a,b);
    switch (sort) {
      case 'date':
        return (a,b) => (b.created || (rank[b.id] - nCards)) - (a.created || (rank[a.id] - nCards));
      case 'edit':
          return (a,b) => (b.edited || b.created || (rank[b.id] - nCards)) - (a.edited || a.created || (rank[a.id] - nCards));
        case 'weight':
        return weightSort;
      case 'eval':
        return (a,b) => (cardWeight && (cardWeight[a.id] ? (cardWeight[b.id] ? (b.priority - a.priority || cardWeight[b.id] - cardWeight[a.id]) : -1) : cardWeight[b.id] ? +1 : weightSort(a,b))) || weightSort(a,b);
      case 'turn':
        return (a,b) => (turns && (typeof(turns[a.id])!=='undefined'?(typeof(turns[b.id])!=='undefined'?turns[a.id]-turns[b.id]:-1):typeof(turns[b.id])!=='undefined'?+1:rankSort(a,b))) || rankSort(a,b);
      case '':
      default:
        break;
      }
    return rankSort;
  }

  setFilterBypass (id, value, cb) {
    let bypass = { ...this.state.filter.bypass }
    bypass[id] = value
    this.setStateAndScroll ({ filter: { ...this.state.filter, bypass } }, cb)
  }

  // pagination
  pageSize() { return this.state.filter.pageSize || this.defaultPageSize }
  showMoreCards() { this.setState({filter:extend({},this.state.filter||{},{pageSize:this.pageSize()+this.defaultPageSize})}) }
        
  // Constants
  get startStage() { return startStage; }
  get gameFilename() { return 'deck.json'; }
  get localStorageKey() { return 'deck-builder' }
  get defaultPageSize() { return 10 }
  get thinkingTimeout() { return 10000 }

}

export default App;
