import { debounce } from 'lodash'
import React, { Component } from 'react';
import { cloneDeep } from './utils';

class DebouncedInput extends Component {
  constructor(props){
    super(props)
    this.prevProps = cloneDeep (props)
    this.state = {
      value: props.value
    }
    // debounce the passed in dispatch method
    this.changed = debounce(this.props.onChange, this.props.debounceDelay || 250)
  }
  componentDidUpdate() {
    if (this.props.value !== this.prevProps.value)
        this.setState ({ value: this.props.value },
            () => this.prevProps.value = this.props.value)
  }
  handleChange = e => {
    // React event weirdness requires storing
    // the synthetic event
    let val = e.target.value
    if (this.props.validateChars)
      val = this.props.validateChars (val)
    this.setState({ value: val }, () => {
      this.changed({target:{value:val}})
    })
  }
  render() {
    return (<label className={this.props.className}>
      <input
        type={this.props.type || 'text'}
        disabled={this.props.disabled}
        autoFocus={this.props.autoFocus && !this.props.disabled}
        size={this.props.size}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        value={this.state.value}
        />
    </label>)
  }
}

export default DebouncedInput