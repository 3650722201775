import './IconSelector.css';
import React, { Component } from 'react';
import GameIcons from './GameIcons';

class IconSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { editRaw: false, showIcons: this.props.iconName && !GameIcons[this.props.iconName] }
  }

  render() {
    const iconDatabase = GameIcons, iconDatabaseKeys = Object.keys(iconDatabase).sort();
    const iconText = this.props.iconText || GameIcons[this.props.iconName] || ''
    return (
      <div className="IconSelector">
        <div className="controls">
            <div className="iconNameInput">
                <input
                    placeholder="Icon name"
                    value={this.props.iconName || ''}
                    onChange={(evt) => {
                        const iconName = evt.target.value;
                        this.setState ({ showIcons: !!iconName, editRaw: false })
                        this.props.onChange ({ iconText: undefined, iconName });
                    }} />
            </div>
            <div className="iconShowIcons">
              <label>
                <input type="checkbox" checked={this.state.showIcons||false} onChange={(evt)=>this.setState({showIcons:evt.target.checked})}/>
                Search icons
              </label>
            </div>
            <div className="iconEditRaw">
              <label>
                <input type="checkbox" checked={this.state.editRaw||false} onChange={(evt)=>this.setState({editRaw:evt.target.checked})}/>
                Paste SVG
              </label>
            </div>
          </div>
          <div className="iconData">
                {iconText && (<div className="preview">
                  <img alt="Icon preview" src={`data:image/svg+xml;utf8,${iconText}`}
                      onClick={(evt)=>this.setState({showIcons:!this.state.showIcons})}/>
                 </div>)}
                {this.state.editRaw &&
                      (<div className="iconText">
                      <textarea 
                          rows="4"
                          cols="16"
                          value={iconText} 
                          onChange={(evt)=>{
                            const iconText = evt.target.value
                            if (!iconText)
                              this.setState ({ editRaw: false });
                            this.props.onChange ({ iconText })
                          }} 
                        />
                      </div>)}
             </div>
          <div className="iconPanel">
          {this.state.showIcons && iconDatabaseKeys.filter((iconName)=>iconName.includes(this.props.iconName||'')).map ((iconName,n) => {
                const iconText = GameIcons[iconName];
                return (<button key={"icon"+n} className="iconButton" onClick={(evt)=>{
                  this.setState({showIcons:false},()=>this.props.onChange({iconText:undefined,iconName}))
                }}>
                        <img alt={iconName} title={iconName} src={`data:image/svg+xml;utf8,${iconText}`} />
                      </button>)
            })}
          </div>
         </div>
    )
  }

}

export default IconSelector;
